/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/sandor-nagy-cropped.jpeg";
import { useTranslation } from 'react-i18next';

function Profile() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">{t('author.name')}</MKTypography>
                <MKButton variant="outlined" color="primary" size="small"
                  href="https://medium.com/@sandorn"
                  target="_blank">
                  {t('author.profile.follow_on_medium.action')}
                </MKButton>
              </MKBox>
              <MKTypography variant="h5">
                {t('author.profile.titles')}
              </MKTypography>
              <Grid container spacing={1} mb={3}>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    {">"}20&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    projects in
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    8+&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    countries.
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    15&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    years and more than
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    6&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    industries.
                  </MKTypography>
                </Grid>
              </Grid>
              <MKTypography variant="body1" fontWeight="light"
                            color="text" padding={1}>
                {t('author.profile.value_prop.tech_lead')}
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light"
                            color="text" padding={1}>
                {t('author.profile.value_prop.engineering_manager')}
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light"
                            color="text" padding={1}>
                {t('author.profile.value_prop.solution_architect')}
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light"
                            color="text" padding={1}>
                {t('author.profile.value_prop.solver')}
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light"
                            color="text" padding={1}>
                {t('author.profile.value_prop.coach')}
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light"
                            color="text" padding={1}>
                {t('author.profile.value_prop.tech_writer')}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
