import React from 'react';
import { Chip, Grid } from '@mui/material';
import { Login, Schedule } from '@mui/icons-material';
import ButtonBlogCard from 'examples/Cards/BlogCards/ButtonBlogCard';
import MKTypography from 'components/MKTypography';

import dashboardImages from 'assets/images/dashboard';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";

const DashboardTile = ({ tile }) => {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    USER
  } = controller;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <ButtonBlogCard
        image={tile.image}
        title={t(tile.title)}
        action={{
          type: "internal",
          route: tile.route,
        }}
        chip={tile.chip && !(USER && tile.chip === "chip.login")
              ? <Chip label={t(tile.chip)} icon={tile.chip_icon}
                      color={tile.chip_color ?? "info"} />
              : <></>}
        badgeContent={tile.badge ? t(tile.badge) : undefined}
        badgeColor={tile.badge_color ?? "primary"}
      />
    </Grid>
  )
}

const DashboardTiles = [
  {
    image: dashboardImages.uptime,
    title: "uptime.title",
    subtitle: "uptime.subtitle",
    route: "/member/uptime",
    category: "MEMBER_ONLY",
    chip: "chip.login",
    chip_icon: (<Login />),
    chip_color: "secondary",
    badge: "badge.alpha",
    is_featured: true,
  },
  {
    image: dashboardImages.pwdgen,
    title: "passwordgen.title",
    subtitle: "passwordgen.description",
    route: "/passwordgen",
    category: "CLIENT_ONLY",
    is_featured: true,
  },
  {
    image: dashboardImages.qrcodify,
    title: "qr.title",
    subtitle: "qr.description",
    route: "/qr",
    category: "CLIENT_ONLY",
  },
  {
    image: dashboardImages.compression,
    title: "compress.title",
    subtitle: "compress.subtitle",
    route: "/compress",
    category: "CLIENT_ONLY",
  },
  {
    image: dashboardImages.recombine,
    title: "recombine.photos.title",
    subtitle: "recombine.photos.subtitle",
    route: "/recombine-photos",
    category: "CLIENT_ONLY",
  },
  {
    image: dashboardImages.tasks,
    title: "tasklist.title",
    subtitle: "tasklist.subtitle",
    route: "/mytasks",
    category: "CLIENT_ONLY",
  },
  {
    image: dashboardImages.mindmap,
    title: "mindmap.title",
    subtitle: "mindmap.subtitle",
    route: "/mindmap",
    category: "CLIENT_ONLY",
  },
  {
    image: dashboardImages.microprojects,
    title: "microprojects.title",
    subtitle: "microprojects.subtitle",
    route: "/microprojects",
    category: "CLIENT_ONLY",
    is_featured: true,
  },
  {
    image: dashboardImages.placeholder,
    title: "exif-edit.title",
    subtitle: "exif-edit.subtitle",
    route: "/coming-soon?exif-edit",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.device,
    title: "mydevice.title",
    subtitle: "mydevice.subtitle",
    route: "/coming-soon?mydevice",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.recipes,
    title: "recipe-price-estimate.title",
    subtitle: "recipe-price-estimate.subtitle",
    route: "/coming-soon?recipe-price-estimate",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.dataextraction,
    title: "extractor.title",
    subtitle: "extractor.subtitle",
    route: "/coming-soon?extractor",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.files,
    title: "converter.title",
    subtitle: "converter.subtitle",
    route: "/coming-soon?converter",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.clipboard,
    title: "clipboard.title",
    subtitle: "clipboard.subtitle",
    route: "/coming-soon?clipboard",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.timer,
    title: "timers.title",
    subtitle: "timers.subtitle",
    route: "/coming-soon?timers",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.maplayers,
    title: "maps.title",
    subtitle: "maps.subtitle",
    route: "/coming-soon?maps",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.personalbio,
    title: "mybio.title",
    subtitle: "mybio.subtitle",
    route: "/coming-soon?mybio",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.businessbio,
    title: "mybusinessbio.title",
    subtitle: "mybusinessbio.subtitle",
    route: "/coming-soon?mybusinessbio",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.notifications,
    title: "getnotified.title",
    subtitle: "getnotified.subtitle",
    route: "/coming-soon?getnotified",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.embeddable,
    title: "embeddable.title",
    subtitle: "embeddable.subtitle",
    route: "/coming-soon?embeddable",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.datamapping,
    title: "data-mapper.title",
    subtitle: "data-mapper.subtitle",
    route: "/coming-soon?data-mapper",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.myhours,
    title: "myhours.title",
    subtitle: "myhours.subtitle",
    route: "/coming-soon?myhours",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.ledbulbs,
    title: "led-prices.title",
    subtitle: "led-prices.subtitle",
    route: "/coming-soon?led-prices",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.writing,
    title: "text-tools.title",
    subtitle: "text-tools.subtitle",
    route: "/coming-soon?text-tools",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.placeholder,
    title: "scheduler.title",
    subtitle: "scheduler.subtitle",
    route: "/coming-soon?scheduler",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.placeholder,
    title: "base64.title",
    subtitle: "base64.subtitle",
    route: "/coming-soon?base64",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.placeholder,
    title: "webhooks.title",
    subtitle: "webhooks.subtitle",
    route: "/coming-soon?webhooks",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.placeholder,
    title: "dns-records.title",
    subtitle: "dns-records.subtitle",
    route: "/coming-soon?dns-records",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.placeholder,
    title: "json.title",
    subtitle: "json.subtitle",
    route: "/coming-soon?json",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.placeholder,
    title: "xml.title",
    subtitle: "xml.subtitle",
    route: "/coming-soon?xml",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
  {
    image: dashboardImages.placeholder,
    title: "wiki.title",
    subtitle: "wiki.subtitle",
    route: "/coming-soon?wiki",
    category: "COMING_SOON",
    chip: "badge.coming-soon",
    chip_icon: (<Schedule />),
  },
];

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyItems="stretch" alignItems="stretch" p={2}>
      <Grid item xs={12}>
        <MKTypography>
          {t('dashboard.featured.title')}
        </MKTypography>
      </Grid>
      {
        DashboardTiles
        .filter(t => t.is_featured)
        .map(tile => 
          <DashboardTile tile={tile} key={tile.title} />
        )
      }

      <Grid item xs={12}>
        <MKTypography>
          {t('dashboard.client_only.title')}
        </MKTypography>
      </Grid>
      {
        DashboardTiles
        .filter(t => !t.is_featured && t.category === "CLIENT_ONLY")
        .map(tile => 
          <DashboardTile tile={tile} key={tile.title} />
        )
      }

      <Grid item xs={12}>
        <MKTypography>
          {t('dashboard.other.title')}
        </MKTypography>
      </Grid>
      {
        DashboardTiles
        .filter(t => !t.is_featured && t.category !== "CLIENT_ONLY")
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(tile => 
          <DashboardTile tile={tile} key={tile.title} />
        )
      }
    </Grid>
  )
}

export default Dashboard;