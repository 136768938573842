import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter
} from 'react-router-dom';
import { config } from './config/firebaseConfig';
import AppConfig from './config/AppConfig';
import MaterialSpinner from './loading/material-spinner';
import ProtectedContent from './ProtectedContent';

import './css/index.css';
import { withTranslation } from 'react-i18next';
import i18n from "./i18n/i18n";

import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
// eslint-disable-next-line
import { useFirebaseRoutesAnalytics } from 'common/analyticsInitialize';

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { useMaterialUIController, signIn } from "context";

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
    USER,
    token
  } = controller;
  const [ APP, setAPP ] = useState({ initialize: false });
  const [ copyright, setCopyright] = useState(null);

  useEffect(() => {
    AppConfig.setTitle();

    let year = 2024;
    let thisYear = new Date().getFullYear();

    if (thisYear > year) {
      year = `${year}-${thisYear} `
    }
    setCopyright({
      name: `Â© ${year}`,
      companyName: ' Limited',
      text: `Â© ${year}  Limited. All rights reserved.`,
      url: 'https://www.tulzkit.com/'
    });

    firebase.initializeApp(config);
    firebase.analytics();

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        signIn(dispatch, user);
      }
      setAPP({ initialize: true });
    });
    // eslint-disable-next-line
  }, []);

  function render() {
    if (APP.initialize === false) {
      return (<MaterialSpinner text="Initializing" x="14" />);
    }

    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <BrowserRouter>
          <div style={{
            height: '100%'
          }}>
            <Routes>
              <Route path="/member/*" element={<PrivateRoute
                USER={USER}
                copyright={copyright}
                token={token} />}
                i18n={i18n}
              />

              <Route path="*" element={<ProtectedContent
                userHasPermissions={USER !== null}
                isAuthenticated={USER !== null}
                USER={USER}
                copyright={copyright}
                token={token} />}
                i18n={i18n} />
            </Routes>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    );
  };

  return render();
}

const PrivateRoute = ({ USER, ...rest }) => {
  const isAuthenticated = USER !== undefined;
  const userHasPermissions = USER !== undefined;
  let pathName = encodeURIComponent(window.location.pathname);
  let redirect = pathName === '' ? null : ('?redirect=' + pathName);

  // redirect if user is not isAuthenticated = false or userHasPermissions = false
  let redirectToLogin = "/sign-in" + redirect;

  if (isAuthenticated === false) return <Navigate to={redirectToLogin} />

  if (userHasPermissions) return <ProtectedContent { ...rest} USER={USER} />
}

export default withTranslation()(App);