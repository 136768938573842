/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import tulzkit from "assets/images/personal-projects/tulzkit.jpeg";
import gots from "assets/images/personal-projects/gots.jpeg";
import sdel from "assets/images/personal-projects/sdel.jpeg";
import weekendz from "assets/images/personal-projects/weekendz.png";
import { useTranslation } from "react-i18next";

function PersonalProjects() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3">
            {t('author.personal.projects.title')}
          </MKTypography>
          <MKTypography variant="body2" mb={6}>
            {t('author.personal.projects.description')}
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={tulzkit}
              title="Tulzkit.com"
              description={t('author.personal.projects.tulzkit.description')}
              action={{
                type: "internal",
                route: "/",
                color: "info",
                label: t('call-to-action.try-out'),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={gots}
              title="Giant of the Stars"
              description={t('author.personal.projects.gots.description')}
              action={{
                type: "external",
                route: "https://medium.com/giant-of-the-stars",
                color: "info",
                label: t('call-to-action.read-more'),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={sdel}
              title="Souldash: Eternal Light"
              description={t('author.personal.projects.sdel.description')}
              action={{
                type: "internal",
                route: "/coming-soon",
                color: "info",
                label: t('call-to-action.coming-soon'),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={weekendz}
              title="Weekendzapp.com"
              titleVariant="h4"
              description={t('author.personal.projects.weekendz.description')}
              action={{
                type: "internal",
                route: "/coming-soon",
                label: t('call-to-action.coming-soon'),
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PersonalProjects;
