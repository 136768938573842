import React from 'react';
import { Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import MKTypography from 'components/MKTypography';

const UptimeFAQ = () => {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  return (
    <Stack direction="column" alignItems="stretch"
      sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8),
            whiteSpace: 'pre-line' }}
      width="stretch" padding={6} spacing={3}>

      <MKTypography variant='h6'>
        {t('uptime.faq.title')}
      </MKTypography>

      <MKTypography variant='h6'>
        {t('uptime.faq.why.question')}
      </MKTypography>

      <MKTypography variant='body2'>
        {t('uptime.faq.why.answer')}
      </MKTypography>

      <MKTypography variant='h6'>
        {t('any.faq.version.question')}
      </MKTypography>

      <MKTypography variant='body2'>
        {t('any.faq.version.answer')}
      </MKTypography>

      <MKTypography variant='h6'>
        {t('uptime.faq.how.question')}
      </MKTypography>

      <MKTypography variant='body2'>
        {t('uptime.faq.how.answer')}
      </MKTypography>

      <MKTypography variant='h6'>
        {t('uptime.faq.examples.question')}
      </MKTypography>

      <MKTypography variant='body2'>
        {t('uptime.faq.examples.answer')}
      </MKTypography>

      <MKTypography variant='h6'>
        {t('uptime.faq.data.question')}
      </MKTypography>

      <MKTypography variant='body2'>
        {t('uptime.faq.data.answer')}
      </MKTypography>

      <MKTypography variant='h6'>
        {t('uptime.faq.learnmore')}
      </MKTypography>
      <Link href='https://en.wikipedia.org/wiki/Uptime' target='blank'>
        Wikipedia - Uptime
      </Link>
    </Stack>
  );
}

export default UptimeFAQ;