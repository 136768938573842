class AppConfig {
  static NAME = 'Tulzkit';
  static VERSION = '0.1';
  static USER = null;

  static setTitle(title) {
      document.title = (!title) ? AppConfig.NAME : `${AppConfig.NAME} | ${title}`;
  }
}

export default AppConfig;