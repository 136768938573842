var config = {
  apiKey: "AIzaSyCq_RGjEiXZRJWi4O23qsuuSiV7Q5DyWOA",
  authDomain: "project-weekendz.firebaseapp.com",
  projectId: "project-weekendz",
  storageBucket: "project-weekendz.appspot.com",
  messagingSenderId: "744765370076",
  appId: "1:744765370076:web:58b6dc6fff53690f9a4e19",
  measurementId: "G-MG6YET264M"
};

export {config};