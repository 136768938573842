import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Card, Grid, List, ListItem, Stack } from "@mui/material";
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import { ft } from 'components/Translations';

const EULA = () => {
  const { t } = useTranslation("legal");
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  return (<>
    <MKBox component="section" py={2}>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          backgroundColor: ({ palette: { white, background }, functions: { rgba } }) => darkMode ? background.sidenav : rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container alignItems="stretch">
          <Grid item xs={12}>
            <Stack direction="column" alignItems="stretch"
              padding={6} spacing={3}
              sx={{ whiteSpace: 'pre-line' }}>

              <MKTypography variant='h3'>
                {t('eula.title')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('eula.intro')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('eula.using')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('eula.modification')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('eula.void')}
              </MKTypography>

              <MKTypography variant='h4'>
                {t('eula.header.account')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('eula.account.security')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('eula.account.manners')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('eula.account.notify')}
              </MKTypography>

              <MKTypography variant='h4'>
                {t('eula.header.contributors')}
              </MKTypography>

              {ft(t, 'eula.contributors.responsibilities')}
              {ft(t, 'eula.contributors.refusal')}

              <MKTypography variant='h4'>
                {t('eula.header.services')}
              </MKTypography>

              {ft(t, 'eula.services.monitoring')}
              {ft(t, 'eula.services.versions.alpha')}
              {ft(t, 'eula.services.other')}

              <MKTypography variant='h4'>
                {t('eula.header.ip')}
              </MKTypography>

              {ft(t, 'eula.ip.notransfer')}
              {ft(t, 'eula.ip.nogrants')}
              {ft(t, 'eula.ip.externals')}

              <MKTypography variant='h4'>
                {t('eula.header.adverts')}
              </MKTypography>

              {ft(t, 'eula.adverts')}

              <MKTypography variant='h4'>
                {t('eula.header.changes')}
              </MKTypography>

              {ft(t, 'eula.changes')}

              <MKTypography variant='h4'>
                {t('eula.header.termination')}
              </MKTypography>

              {ft(t, 'eula.termination')}

              <MKTypography variant='h4'>
                {t('eula.header.warranties')}
              </MKTypography>

              {ft(t, 'eula.warranties')}

              <MKTypography variant='h4'>
                {t('eula.header.liabilities')}
              </MKTypography>

              {ft(t, 'eula.liabilities')}

              <MKTypography variant='h4'>
                {t('eula.header.representation')}
              </MKTypography>

              {ft(t, 'eula.representation')}

              <MKTypography variant='h4'>
                {t('eula.header.indemnification')}
              </MKTypography>

              {ft(t, 'eula.indemnification')}

              <MKTypography variant='h4'>
                {t('eula.header.redistribution')}
              </MKTypography>

              {ft(t, 'eula.redistribution.noresale')}
              {ft(t, 'eula.redistribution.nocomply')}

              <MKTypography variant='h4'>
                {t('eula.header.contact')}
              </MKTypography>

              {ft(t, 'eula.contact')}
              {ft(t, 'eula.contact.ip')}
              {ft(t, 'eula.contact.sensitive-info')}
              {ft(t, 'eula.contact.email-conditions')}
              {ft(t, 'eula.contact.speculative')}

              <MKTypography variant='h4'>
                {t('eula.header.miscellaneous')}
              </MKTypography>

              {ft(t, 'eula.miscellaneous')}

              <MKTypography variant='h4'>
                {t('eula.header.changelog')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  {ft(t, 'eula.changelog.20240425')}
                </ListItem>
              </List>

              <MKTypography variant='h4'>
                {t('eula.header.provider')}
              </MKTypography>

              {ft(t, 'eula.provider')}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </MKBox></>
  )
}

export default EULA;