import firebase from 'firebase/compat/app';

class UptimeServices {
  init = async function() {
    this.apiUrl = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}` : 'https://api.tulzkit.com';
  }

  listMonitors = async function() {
    const partUrl = `/monitors`;
    const authHeaders = { mode: 'cors', headers: {
        'Authorization': 'Bearer ' + await firebase.auth().currentUser.getIdToken(),
        'Content-Type': 'application/json'
      }
    };
    return (await fetch(this.apiUrl + partUrl, { ...authHeaders,
      method: 'GET'
    })).json();
  }

  createMonitor = async function(url, schedules) {
    const partUrl = `/monitors`;
    const authHeaders = { mode: 'cors', headers: {
        'Authorization': 'Bearer ' + await firebase.auth().currentUser.getIdToken(),
        'Content-Type': 'application/json'
      }
    };
    return (await fetch(this.apiUrl + partUrl, { ...authHeaders,
      method: 'PUT',
      body: JSON.stringify({ url, schedules })
    })).json();
  }

  updateMonitor = async function(id, url, schedules) {
    const partUrl = `/monitors/`;
    const authHeaders = { mode: 'cors', headers: {
        'Authorization': 'Bearer ' + await firebase.auth().currentUser.getIdToken(),
        'Content-Type': 'application/json'
      }
    };
    return (await fetch(this.apiUrl + partUrl + id, { ...authHeaders,
      method: 'PUT',
      body: JSON.stringify({ url, schedules })
    })).json();
  }

  getMonitor = async function(id) {
    const partUrl = `/monitors/`;
    const authHeaders = { mode: 'cors', headers: {
        'Authorization': 'Bearer ' + await firebase.auth().currentUser.getIdToken(),
        'Content-Type': 'application/json'
      }
    };
    return (await fetch(this.apiUrl + partUrl + id, { ...authHeaders,
      method: 'GET'
    })).json();
  }

  deleteMonitor = async function(id) {
    const partUrl = `/monitors/`;
    const authHeaders = { mode: 'cors', headers: {
        'Authorization': 'Bearer ' + await firebase.auth().currentUser.getIdToken(),
        'Content-Type': 'application/json'
      }
    };
    return (await fetch(this.apiUrl + partUrl + id, { ...authHeaders,
      method: 'DELETE'
    })).json();
  }
};

export default UptimeServices;