// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
//import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();

const footer_links = {
  brand: {
    name: "Tulzkit",
    image: "/tulzkit.png",
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/nagysa1313/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/nagysa1313",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/nagysa1313",
    },
    // {
    //   icon: <YouTubeIcon />,
    //   link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    // },
  ],
  menus: [
    {
      name: "route.main.author",
      items: [
        { name: "route.author", route: "/author" },
        { name: "route.blog", href: "https://www.medium.com/nagysa1313" },
      ],
    },
    {
      name: "route.main.legal",
      items: [
        { name: "route.eula", route: "/eula" },
        { name: "route.privacy", route: "/privacy" },
        { name: "route.licenses", route: "/licences" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Tulzkit.com by{" "}
      <MKTypography
        component="a"
        href="https://www.tulzkit.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Sandor Nagy
      </MKTypography>
      .
    </MKTypography>
  ),
};

export default footer_links;