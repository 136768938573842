import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { styled } from '@mui/material/styles';
import { Box, Card, Grid, Link, Stack, TextField } from "@mui/material";
import { CloudUpload, Refresh, Save } from '@mui/icons-material';
import usePersistantState from '../../common/usePersistentState';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Compress = () => {
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [selectedFileName, setSelectedFileName] = useState(undefined);
    const [compressedFile, setCompressedFile] = useState(undefined);
    const [width, setWidth] = usePersistantState("COMPRESS_TO_IMAGE_WIDTH", 512);
    const [height, setHeight] = useState();
    const [originalWidth, setOriginalWidth] = useState();
    const [originalHeight, setOriginalHeight] = useState();
    const { t } = useTranslation();
    const [controller] = useMaterialUIController();
    const {
      darkMode,
    } = controller;

    function updateFile() {
        const compressedUrl = downscaleImage(selectedFile, width);
        setCompressedFile(compressedUrl);
    }

    // Take an image URL, downscale it to the given width, and return a new image URL.
    function downscaleImage(dataUrl, newWidth, imageType, imageArguments) {
        var image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;

        // Provide default values
        imageType = imageType || "image/jpeg";
        imageArguments = imageArguments || 0.7;

        // Create a temporary image so that we can compute the height of the downscaled image.
        image = new Image();
        image.src = dataUrl;
        oldWidth = image.width;
        oldHeight = image.height;
        newHeight = Math.floor(oldHeight / oldWidth * newWidth)

        // Create a temporary canvas to draw the downscaled image on.
        canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the downscaled image on the canvas and return the new data URL.
        ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        newDataUrl = canvas.toDataURL(imageType, imageArguments);
        setHeight(newHeight);
        setOriginalHeight(oldHeight);
        setOriginalWidth(oldWidth);
        return newDataUrl;
    }

    function saveImage(downloadUrl) {
        const downloadImage = document.createElement("a");
        document.body.appendChild(downloadImage);
        downloadImage.setAttribute("download", "image");
        downloadImage.href = downloadUrl;
        downloadImage.download = selectedFileName;
        downloadImage.click();
        downloadImage.remove();
    };

    return (
      <>
        <MKBox component="section" py={2}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            backgroundColor: ({ palette: { white, background }, functions: { rgba } }) => darkMode ? background.sidenav : rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
        <Grid container alignItems="stretch">
            <Grid item xs={12} md={12} lg={8}>
            <Stack direction="column" alignItems="stretch"
                padding={6} maxWidth={1024} spacing={3}>

                <MKTypography variant='h4'>
                    {t('compress.title')}
                </MKTypography>

                <MKTypography variant='h5'>
                    {t('compress.subtitle')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('compress.description')}
                </MKTypography>

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Stack direction="column" spacing={2}
                            margin={2}>
                            <MKTypography variant='h6'>
                                {t('compress.original.title')}
                            </MKTypography>

                            <Box component="img" id='selectedFilePreview'
                                sx={{
                                    height: "auto",
                                    width: "auto",
                                }}
                                src={selectedFile}
                            />

                            <MKTypography variant="body1">
                                {originalWidth} px X {originalHeight} px
                            </MKTypography>

                            <MKButton
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUpload />}>
                                {t('compress.select')}
                                <VisuallyHiddenInput type="file"
                                    accept='image/*'
                                    onChange={(event) => {
                                        if (event.target.files && event.target.files[0]) {
                                          setSelectedFileName(event.target.files[0].name);
                                          const url = URL.createObjectURL(event.target.files[0]);
                                          setSelectedFile(url);
                                        }
                                    }} />
                            </MKButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack direction="column" spacing={2}
                            margin={2}>
                            <MKTypography variant='h6'>
                                {t('compress.result.title')}
                            </MKTypography>

                            <Box component="img"
                                sx={{
                                    height: "auto",
                                    width: "auto",
                                }}
                                src={compressedFile}
                            />

                            <MKTypography variant="body1">
                                {width} px X {height} px
                            </MKTypography>

                            <Stack direction="row"
                                   justifyContent="space-between">
                                <MKButton
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={0}
                                    startIcon={<Refresh />}
                                    onClick={(event) => {
                                        updateFile();
                                    }}>
                                    {t('compress.refresh')}
                                </MKButton>
                                <MKButton
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={0}
                                    startIcon={<Save />}
                                    onClick={(event) => {
                                        saveImage(compressedFile);
                                    }}>
                                    {t('compress.save')}
                                </MKButton>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

                <Stack direction="column" alignItems="stretch"
                    width="stretch" padding={1} spacing={1}
                    sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}>

                    <MKTypography variant='h6'>
                        {t('compress.settings.title')}
                    </MKTypography>

                    <TextField id="width"
                        label={t('compress.width.title')}
                        value={width}
                        onChange={(event) => {
                            parseInt(setWidth(event.target.value));
                        }} />
                </Stack>
            </Stack>
            </Grid>
            
            <Grid item xs={12} md={12} lg={4}>
            <Stack direction="column" alignItems="stretch"
                sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}
                width="stretch" padding={6} spacing={3}>
                <MKTypography variant='h6'>
                    {t('compress.faq.title')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('compress.faq.why.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('compress.faq.why.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('compress.faq.how.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('compress.faq.how.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('compress.faq.data.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('compress.faq.data.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('compress.faq.learnmore')}
                </MKTypography>
                <Link href='https://en.wikipedia.org/wiki/Image_compression'>
                    Wikipedia - Image compression
                </Link>
            </Stack>
            </Grid>
        </Grid>
        </Card>
        </MKBox>
        </>
    )
}

export default Compress;