import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Card, Grid, List, ListItem, Stack } from "@mui/material";
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';

const Privacy = () => {
  const { t } = useTranslation("legal");
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  return (<>
    <MKBox component="section" py={2}>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          backgroundColor: ({ palette: { white, background }, functions: { rgba } }) => darkMode ? background.sidenav : rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={12}>
            <Stack direction="column" alignItems="stretch"
              padding={6} spacing={3}
              sx={{ whiteSpace: 'pre-line' }}>

              <MKTypography variant='h3'>
                {t('privacy.title')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.intro')}
              </MKTypography>

              <MKTypography variant='h4'>
                {t('privacy.header.scope')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.scope')}
              </MKTypography>

              <MKTypography variant='h4'>
                {t('privacy.header.collection')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.collection')}
              </MKTypography>

              <MKTypography variant='h6'>
                {t('privacy.collection.provided')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.collection.provided.communications')}
                    </Trans>
                  </MKTypography>
                </ListItem>
              </List>

              <MKTypography variant='h6'>
                {t('privacy.collection.automatic')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.collection.automatic.logs')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.collection.automatic.usage')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.collection.automatic.location')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.collection.automatic.cookies')}
                    </Trans>
                  </MKTypography>
                </ListItem>
              </List>

              <MKTypography variant='h4'>
                {t('privacy.header.usage')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.usage')}
              </MKTypography>

              <MKTypography variant='h6'>
                {t('privacy.usage.purpose')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.usage.purpose.provide')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.usage.purpose.develop')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.usage.purpose.prevent')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.usage.purpose.communicate')}
                    </Trans>
                  </MKTypography>
                </ListItem>
              </List>

              <MKTypography variant='h6'>
                {t('privacy.usage.legal')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.usage.legal.brief')}
              </MKTypography>
              
              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.usage.legal.commitments')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.usage.legal.compliance')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.usage.legal.interest')}
                    </Trans>
                  </MKTypography>
                </ListItem>
              </List>

              <MKTypography variant='h4'>
                {t('privacy.header.sharing')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.sharing')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.sharing.legal')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.sharing.protect')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.sharing.aggregated')}
                    </Trans>
                  </MKTypography>
                </ListItem>
              </List>

              <MKTypography variant='h4'>
                {t('privacy.header.security')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.security')}
              </MKTypography>

              <MKTypography variant='h4'>
                {t('privacy.header.rights')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.rights')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    {t('privacy.rights.access')}
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    {t('privacy.rights.correction')}
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    {t('privacy.rights.object')}
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    {t('privacy.rights.limit')}
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    {t('privacy.rights.portability')}
                  </MKTypography>
                </ListItem>
              </List>

              <MKTypography variant='body2'>
                {t('privacy.rights.debrief')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.rights.complaint')}
              </MKTypography>

              <MKTypography variant='h4'>
                {t('privacy.header.contact')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.contact')}
                <MKButton component="a" variant="outlined"
                  href={"mailto:" + t('privacy.contact.email.address')}>
                  {t('privacy.contact.here')}
                </MKButton>
              </MKTypography>

              <MKTypography variant='h4'>
                {t('privacy.header.transfer')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.transfer')}
              </MKTypography>

              <MKTypography variant='h4'>
                {t('privacy.header.subprocessors')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.subprocessor.aws')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.subprocessor.firebase.auth')}
                    </Trans>
                  </MKTypography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.subprocessor.firebase.analytics')}
                    </Trans>
                  </MKTypography>
                </ListItem>
              </List>

              <MKTypography variant='h4'>
                {t('privacy.header.changes')}
              </MKTypography>

              <MKTypography variant='body2'>
                {t('privacy.changes.brief')}
              </MKTypography>

              <MKTypography variant='h6'>
                {t('privacy.changes.subtitle')}
              </MKTypography>

              <List sx={{ listStyleType: 'disc', color: 'Highlight', paddingLeft: 6 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <MKTypography variant='body2'>
                    <Trans>
                      {t('privacy.changes.20240424')}
                    </Trans>
                  </MKTypography>
                </ListItem>
              </List>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </MKBox></>
  )
}

export default Privacy;