import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { styled } from '@mui/material/styles';
import { Box, Card, Grid, Link, Stack, TextField, Tooltip } from "@mui/material";
import { CloudUpload, Delete } from '@mui/icons-material';
import usePersistantState from '../../common/usePersistentState';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const RecombinePhotos = () => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewAnim, setPreviewAnim] = useState(null);
  const [origAnim, setOrigAnim] = useState(null);
  const [cellColumn, setCellColumn] = usePersistantState("RECOMBINE_PHOTOS_CELL_COLUMN", 4);
  const [cellRow, setCellRow] = usePersistantState("RECOMBINE_PHOTOS_CELL_ROW", 4);
  const [cellWidth, setCellWidth] = usePersistantState("RECOMBINE_PHOTOS_CELL_WIDTH", 256);
  const [cellHeight, setCellHeight] = usePersistantState("RECOMBINE_PHOTOS_CELL_HEIGHT", 256);
  const [fps, setFPS] = usePersistantState("RECOMBINE_PHOTOS_FPS", 15);
  const [previewBackground, setPreviewBackground] = usePersistantState("RECOMBINE_PHOTOS_BACKGROUND", "white");
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  const updatePreview = () => {
    if (!selectedFiles) return;

    //console.log({selectedFiles, cellColumn, cellRow, cellWidth, cellHeight});
    const cw = cellColumn * cellWidth;
    const ch = cellRow * cellHeight;
    //console.log({cw, ch});

    // Create a temporary canvas to draw the downscaled image on.
    const canvas = document.createElement("canvas");
    canvas.width = cw;
    canvas.height = ch;

    // Draw the downscaled image on the canvas and return the new data URL.
    const ctx = canvas.getContext("2d");
    for (let i = 0; i < selectedFiles.length; i++) {
      const f = selectedFiles[i];
      const image = new Image();
      image.src = f.url;
      const x = (i % cellColumn) * cellWidth;
      const y = Math.floor(i / cellColumn) * cellHeight;
      //console.log({ i, x, y, cellWidth, cellHeight, file: f.name});
      ctx.drawImage(image, x, y, cellWidth, cellHeight);
    }
    const newDataUrl = canvas.toDataURL("image/png", 1.0);
    //console.log({newDataUrl, ctx});
    setPreview(newDataUrl);
  };

  let frame = 0;
  const updateAnim = () => {
    if (!selectedFiles) return;
    const f = selectedFiles[frame];
    if (!f) return;

    const canvas = document.createElement("canvas");
    canvas.width = cellWidth;
    canvas.height = cellHeight;

    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = f.url;
    ctx.drawImage(image, 0, 0, cellWidth, cellHeight);

    setPreviewAnim(canvas.toDataURL("image/png", 1.0));

    canvas.width = image.width;
    canvas.height = image.width;
    const ctxOrig = canvas.getContext("2d");
    ctxOrig.drawImage(image, 0, 0, image.width, image.width);

    setOrigAnim(canvas.toDataURL("image/png", 1.0));

    frame++;
    if (frame >= selectedFiles?.length) frame = 0;
  };

  useEffect(() => {
    updatePreview();
    // eslint-disable-next-line
  }, [selectedFiles, cellWidth, cellHeight, cellColumn, cellRow]);

  useEffect(() => {
    let animTimeout;

    const update = () => {
      updateAnim();
      animTimeout = setTimeout(update, 1000 / parseFloat(fps));
    }
    update();

    return () => {
      clearTimeout(animTimeout);
    }
    // eslint-disable-next-line
  }, [selectedFiles, fps, cellWidth, cellHeight]);

  return (
    <>
      <MKBox component="section" py={2}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            backgroundColor: ({ palette: { white, background }, functions: { rgba } }) => darkMode ? background.sidenav : rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container alignItems="stretch">
            <Grid item xs={12} md={12} lg={8}>
              <Stack direction="column" alignItems="stretch"
                padding={6} spacing={3}>
                <MKTypography variant='h4'>
                  {t('recombine.photos.title')}
                </MKTypography>

                <MKTypography variant='h5'>
                  {t('recombine.photos.subtitle')}
                </MKTypography>

                <MKTypography variant='body2'>
                  {t('recombine.photos.description')}
                </MKTypography>

                <Grid container>
                  <Grid item xs={12}>
                    <Stack direction="column" spacing={2} margin={2}>
                      <MKTypography variant='h6'>
                        {t('recombine.photos.select.title')}
                      </MKTypography>

                      <Grid container>
                        <Grid item xs={6}>
                          <MKButton
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUpload />}>
                          {t('recombine.photos.select')}
                          <VisuallyHiddenInput type="file"
                            accept='image/*' multiple
                            onChange={(event) => {
                              if (event.target.files) {
                                const files = [];
                                if (selectedFiles && selectedFiles.length > 0) files.push(...selectedFiles);
                                for (let i = 0; i < event.target.files.length; i++) {
                                  const f = event.target.files[i];
                                  const url = URL.createObjectURL(f);
                                  f.url = url;
                                  files.push(f);
                                }
                                setSelectedFiles(files);
                              }
                            }} />
                      </MKButton>
                        </Grid>

                        <Grid item xs={6}>
                          <MKButton variant="outlined" color="error" startIcon={<Delete />}
                            onClick={(event) => {
                              setSelectedFiles(null);
                              setPreview(null);
                              setPreviewAnim(null);
                              setOrigAnim(null);
                            }}>
                            {t('recombine.photos.clear')}
                          </MKButton>
                        </Grid>
                      </Grid>

                      {selectedFiles ?
                        <Stack direction="row">
                          {selectedFiles?.map((file) =>
                            <Tooltip title={file.name} key={file.name}>
                              <Box component="img" id='selectedFilePreview'
                                sx={{ height: "64px", width: "64px" }}
                                src={file.url} m={.3}
                                alt={file.name}
                              />
                            </Tooltip>)
                          }
                        </Stack>
                        : <></>}

                      <MKTypography variant='h6'>
                        {t('recombine.photos.combined.title')}
                      </MKTypography>

                      <Grid container justifyItems="stretch">
                        <Grid item xs={6} justifyItems="stretch">
                          <TextField id="cell-width"
                            label={t('recombine.photos.cell-width.title')}
                            value={cellWidth}
                            onChange={(event) => {
                              parseInt(setCellWidth(event.target.value));
                            }} />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField id="cell-height"
                            label={t('recombine.photos.cell-height.title')}
                            value={cellHeight}
                            onChange={(event) => {
                              parseInt(setCellHeight(event.target.value));
                            }} />
                        </Grid>
                      </Grid>

                      <Grid container justifyItems="stretch">
                        <Grid item xs={6} justifyItems="stretch">
                          <TextField id="cell-column"
                            label={t('recombine.photos.cell-column.title')}
                            value={cellColumn}
                            onChange={(event) => {
                              parseInt(setCellColumn(event.target.value));
                            }} />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField id="cell-row"
                            label={t('recombine.photos.cell-row.title')}
                            value={cellRow}
                            onChange={(event) => {
                              parseInt(setCellRow(event.target.value));
                            }} />
                        </Grid>
                      </Grid>

                      <MKBox sx={{ "background-color": previewBackground }} width={true}>
                        <Box component="img" width={true} src={preview} />
                      </MKBox>

                      <MKTypography variant='h6'>
                        {t('recombine.photos.animations.title')}
                      </MKTypography>

                      <Grid container justifyItems="stretch">
                        <Grid item xs={6} justifyItems="stretch">
                          <TextField id="fps"
                            label={t('recombine.photos.fps.title')}
                            value={fps}
                            onChange={(event) => {
                              parseInt(setFPS(event.target.value));
                            }} />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField id="background"
                            label={t('recombine.photos.background.title')}
                            value={previewBackground}
                            onChange={(event) => {
                              parseInt(setPreviewBackground(event.target.value));
                            }} />
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <MKBox sx={{ "background-color": previewBackground }} width={true}>
                            <Box component="img" width={true} src={origAnim} />
                          </MKBox>
                        </Grid>

                        <Grid item xs={6}>
                          <MKBox sx={{ "background-color": previewBackground }} width={true}>
                            <Box component="img" width={true} src={previewAnim} />
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <Stack direction="column" alignItems="stretch"
                sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}
                width="stretch" padding={6} spacing={3}>
                <MKTypography variant='h6'>
                  {t('recombine.photos.faq.title')}
                </MKTypography>

                <MKTypography variant='h6'>
                  {t('recombine.photos.faq.why.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                  {t('recombine.photos.faq.why.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                  {t('recombine.photos.faq.how.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                  {t('recombine.photos.faq.how.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                  {t('recombine.photos.faq.data.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                  {t('recombine.photos.faq.data.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                  {t('recombine.photos.faq.learnmore')}
                </MKTypography>

                <MKTypography variant='body2'>
                  <Link href='https://en.wikipedia.org/wiki/Texture_atlas'>
                    Wikipedia - Texture Atlas
                  </Link>
                </MKTypography>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </MKBox>
    </>
  )
}

export default RecombinePhotos;