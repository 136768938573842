/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Badge, CardActionArea } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function ButtonBlogCard({ image, title, description, action, chip, titleVariant, badgeContent, badgeColor }) {
  function wrapInBadge(content) {
    if (badgeContent) {
      return (
        <Badge badgeContent={badgeContent} color={badgeColor ?? "primary"}
          overlap="rectangular">
          {content}
        </Badge>
      )
    }

    return content;
  }

  return (
    <MKBox padding={2}>
      {wrapInBadge(
        <Card
          sx={{
            backgroundImage: ({ palette: { black }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(rgba(black.main, 0.5), rgba(black.main, 0.5))}, url(${image})`,
            backgroundSize: "cover",
          }}
        >
          <CardActionArea
            LinkComponent={action.type === "internal" ? RouterLink : 'a'}
            to = {action.type === "internal" ? action.route : null}
            href={action.type === "internal" ? null : action.route}>
            <MKBox p={2}>
              <MKBox minHeight="10.625rem" my="auto" py={1}>
                <MKTypography
                  variant={titleVariant ?? "h2"}
                  color="white"
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  {title}
                </MKTypography>
                {description ? (
                <MKTypography variant="body2" color="white" my={2}>
                  {description}
                </MKTypography>
                ) : <></>}
                {chip}
              </MKBox>
            </MKBox>
          </CardActionArea>
        </Card>
      )}
    </MKBox>
  );
}

// Typechecking props for the BackgroundBlogCard
ButtonBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired
  }).isRequired,
  chip: PropTypes.any,
  badgeContent: PropTypes.string,
  badgeColor: PropTypes.string
};

export default ButtonBlogCard;
