import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Stack, TextField } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Backspace, Delete, Save } from '@mui/icons-material';
import UptimeFAQ from './UptimeFAQ';
import UptimeMonitor from './UptimeMonitor';
import UptimeServices from 'services/uptime.service';
import useNotification from 'common/useNotification';
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import MaterialSpinner from 'loading/material-spinner';

const UptimeEditMonitor = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [url, setUrl] = useState("");
  const [frequency, setFrequency] = useState("ONE_HOUR");
  const [monitor, setMonitor] = useState(null);

  const safeParseURL = (url) => {
    return url.indexOf(':') > 0 ? new URL(url) : new URL("https://" + url);
  }

  async function onDelete() {
    if (loading) return;
    // TODO: add are you sure box
    try {
      setLoading(true);
      const service = new UptimeServices();
      await service.init();
      await service.deleteMonitor(id);
      sendNotification({ msg: t('uptime.monitor.delete.success'), variant: 'success' });
      navigate('/member/uptime');
    }
    catch (e) {
      console.log(e);
      sendNotification({ msg: t('uptime.monitor.delete.failed'), variant: 'error' });
    }
    finally {
      setLoading(false);
    }
  }

  async function onSubmit() {
    if (loading) return;
    try {
      setLoading(true);
      const parsedUrl = safeParseURL(url);
      const service = new UptimeServices();
      await service.init();
      if (id) {
        await service.updateMonitor(id, parsedUrl.toString(), { selected: frequency });
      } else {
        await service.createMonitor(parsedUrl.toString(), { selected: frequency });
      }
      sendNotification({ msg: t('uptime.monitor.save.success'), variant: 'success', data: parsedUrl });
      navigate('/member/uptime');
    }
    catch (e) {
      console.log(e);
      sendNotification({ msg: t('uptime.monitor.save.failed'), variant: 'error' });
    }
    finally {
      setLoading(false);
    }
  };

  const onRefresh = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const service = new UptimeServices();
      await service.init();
      const monitor = await service.getMonitor(id);
      setUrl(monitor.url);
      setFrequency(monitor.schedules.find(f => f !== undefined)?.selected.replace("CUSTOM", ""));
      setMonitor(monitor);
      setLoaded(true);
    }
    catch (e) {
      console.log(e);
      sendNotification({ msg: t('uptime.monitor.load.failed'), variant: 'error' });
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id && !loaded) {
      onRefresh();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="column" alignItems="stretch"
              padding={3} spacing={3}>
              <Stack direction="row">
                <MKButton onClick={() => navigate(-1)} color='primary' variant="text">
                  <Backspace sx={{ transform: "scale(1.5)" }} />
                </MKButton>

                <MKTypography variant='h4'>
                  {id ? t('uptime.edit.title') : t('uptime.add.title')}
                </MKTypography>
              </Stack>

              <MKTypography variant='h5'>
                {id ? t('uptime.edit.subtitle') : t('uptime.add.subtitle')}
              </MKTypography>

              <TextField id="url" name='url'
                label={t('uptime.url.title')}
                value={url} disabled={loading}
                onChange={(event) => {
                  setUrl(event.target.value);
                }}
              />

              <MKTypography variant='body2'>
                {t('uptime.short-name.title')}: {url ? safeParseURL(url).hostname?.replace("www.", "") : t('uptime.short-name.preview')}
              </MKTypography>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">{t('uptime.frequency.title')}</InputLabel>
                <Select
                  labelId='demo-simple-select-standard-label'
                  id="demo-simple-select-standard"
                  value={frequency} disabled={loading}
                  onChange={(event) => {
                    setFrequency(event.target.value);
                  }}>
                  <MenuItem value="FIVE_MINUTES">{t('uptime.frequency.five_minutes')}</MenuItem>
                  <MenuItem value="THIRTY_MINUTES">{t('uptime.frequency.thirty_minutes')}</MenuItem>
                  <MenuItem value="ONE_HOUR">{t('uptime.frequency.one_hour')}</MenuItem>
                </Select>
              </FormControl>

              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <LoadingButton variant="contained" size="large"
                    onClick={onSubmit} loading={loading} startIcon={<Save />}
                    sx={{
                      width: '95%', margin: 1,
                    }}>
                    <MKTypography variant='body2'>
                      {t('uptime.edit.save')}
                    </MKTypography>
                  </LoadingButton>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  {loading ?
                    <MaterialSpinner />
                    :
                    id ?
                      <MKButton variant='contained' size='large' color="error"
                        onClick={onDelete} startIcon={<Delete />}
                        sx={{ width: '95%', margin: 1 }}>
                        <MKTypography variant='body2'>
                          {t('uptime.edit.delete')}
                        </MKTypography>
                      </MKButton>
                      : <></>}
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          {monitor ?
            <Grid item xs={12}>
              <UptimeMonitor m={monitor} presenting={true} />
            </Grid>
            : <></>}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
        <UptimeFAQ />
      </Grid>
    </Grid>
  );
}

export default UptimeEditMonitor;