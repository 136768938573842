import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Button, Collapse, Grid, Stack, TextField } from "@mui/material";
import { Checkbox, List, ListItem } from "@mui/material";
import { Add, Delete, Done, Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import usePersistantState from '../../common/usePersistentState.js';
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import MKBox from 'components/MKBox/index.js';
import { projectsReducer, tasksReducer } from 'reducers/ProjectsReducer.js';

const Task = ({ task, onChange, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <ListItem key={task.id}>
      <Grid container alignItems="center">
        <Grid item xs={1} minWidth={42}>
          <Checkbox checked={task.done}
            onChange={(event) => {
              onChange({
                ...task,
                done: event.target.checked
              });
            }}
          />
        </Grid>

        {isEditing ?
          <>
            <Grid item xs={true}>
              <TextField fullWidth
                value={task.text}
                onChange={(event) => {
                  onChange({
                    ...task,
                    text: event.target.value
                  });
                }}
                onKeyDown={(event) => {
                  if (event.code === "Enter") {
                    setIsEditing(false);
                  }
                }} />
            </Grid>

            <Grid item xs={1} minWidth={42}>
              <MKButton onClick={() => setIsEditing(false)}
                width={42}>
                <Done />
              </MKButton>
            </Grid>
          </>
          :
          <>
            <Grid item xs={true}>
              <MKTypography variant='body2'>
                {task.text}
              </MKTypography>
            </Grid>
            <Grid item xs={1} minWidth={64}>
              <MKButton onClick={() => setIsEditing(true)}
                variant="text">
                <Edit />
              </MKButton>
            </Grid>
          </>}
        <Grid item xs={1} minWidth={64}>
          <MKButton onClick={() => onDelete(task.id)}
            color='error' variant="text">
            <Delete />
          </MKButton>
        </Grid>
      </Grid>
    </ListItem>
  )
};

const Project = ({ project, onChange, onDelete, onAddTask }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [newTaskText, setNewTaskText] = useState('');

  return (
    <Grid container alignItems="center">
      {isEditing ?
        <>
          <Grid item xs={true}>
            <TextField fullWidth
              value={project.name}
              onChange={(event) => {
                onChange({
                  ...project,
                  name: event.target.value
                });
              }}
              onKeyDown={(event) => {
                if (event.code === "Enter") {
                  setIsEditing(false);
                }
              }} />
          </Grid>

          <Grid item xs={1} minWidth={42}>
            <MKButton onClick={() => setIsEditing(false)}
              width={42}>
              <Done />
            </MKButton>
          </Grid>
        </>
        :
        <>
          <Grid item xs={true}>
            <MKTypography variant='body'>
              {project.name}
            </MKTypography>
          </Grid>
          <Grid item xs={1} minWidth={64}>
            <MKButton onClick={() => setIsEditing(true)}
              variant="text">
              <Edit />
            </MKButton>
          </Grid>
        </>}

      <Grid item xs={1} minWidth={64}>
        <MKButton onClick={() => onDelete(project.id)}
          color='error' variant="text">
          <Delete />
        </MKButton>
      </Grid>

      <Grid item xs={10}>
        <TextField fullWidth
          value={newTaskText}
          onChange={(event) => {
            setNewTaskText(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.code === "Enter") {
              onAddTask(newTaskText, project.id);
              setNewTaskText('');
            }
          }}
          label={t('microprojects.newtasktext.title')} />
      </Grid>

      <Grid item xs={1} minWidth={64}>
        <Button onClick={() => {
          onAddTask(newTaskText, project.id);
          setNewTaskText('');
        }}>
          <Add />
        </Button>
      </Grid>
    </Grid>
  );
}

const ProjectList = () => {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  const [projects, projectDispatch] = useReducer(projectsReducer, [], handleProjectsLoad)
  const [tasks, taskDispatch] = useReducer(tasksReducer, [], handleTasksLoad);
  const [newProjectName, setNewProjectName] = useState("");
  const [settingsOpen, setSettingsOpen] = usePersistantState(false);

  function handleAddProject(name) {
    projectDispatch({
      type: 'added',
      id: Math.random() * 10000,
      name
    });
  }

  function handleChangeProject(project) {
    projectDispatch({
      type: 'changed',
      project
    });
  }

  function handleDeleteProject(id) {
    projectDispatch({
      type: 'deleted',
      id
    });
    taskDispatch({
      type: 'project_deleted',
      project_id: id
    });
  }

  function removeAllProjects() {
    projectDispatch({
      type: 'clear'
    });
  }

  function handleAddTask(text, project_id) {
    taskDispatch({
      type: 'added',
      id: Math.random() * 10000,
      text,
      project_id
    });
  }

  function handleChangeTask(task) {
    taskDispatch({
      type: 'changed',
      task: task
    });
  }

  function handleDeleteTask(taskId) {
    taskDispatch({
      type: 'deleted',
      id: taskId
    });
  }

  function removeAllDone() {
    taskDispatch({
      type: 'remove_done'
    });
  }

  function removeAllTasks() {
    taskDispatch({
      type: 'clear'
    });
  }

  function handleStore() {
    localStorage.setItem("PROJECTS", JSON.stringify(projects));
    localStorage.setItem("PROJECT_TASKS", JSON.stringify(tasks));
  }

  useEffect(() => {
    handleStore();
  });

  function handleTasksLoad() {
    return JSON.parse(localStorage.getItem("PROJECT_TASKS")) ?? [];
  }
  function handleProjectsLoad() {
    return JSON.parse(localStorage.getItem("PROJECTS")) ?? [];
  }

  function handleSettingsOpen() {
    setSettingsOpen(!settingsOpen);
  }

  return (
    <Grid container>
      <Grid item md={12} lg={8}>
        <Stack direction="column"
          padding={6} spacing={3}>

          <MKTypography variant='h4'>
            {t('microprojects.title')}
          </MKTypography>

          <MKTypography variant='h5'>
            {t('microprojects.subtitle')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('microprojects.description')}
          </MKTypography>

          <Grid container alignItems="center">
            <Grid item xs={true}>
              <TextField fullWidth
                value={newProjectName}
                onChange={(event) => {
                  setNewProjectName(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.code === "Enter") {
                    handleAddProject(newProjectName);
                    setNewProjectName('');
                  }
                }}
                label={t('microprojects.newprojectname.title')} />
            </Grid>

            <Grid item xs={1} minWidth={64}>
              <Button onClick={() => {
                handleAddProject(newProjectName);
                setNewProjectName('');
              }}>
                <Add />
              </Button>
            </Grid>
          </Grid>

          {projects && projects.length > 0 ?
            projects.map((project) => {
              const projectTasks = tasks?.filter(t => t.project_id === project.id);
              return (
                <MKBox key={project.id}>
                  <Project project={project}
                    onChange={handleChangeProject}
                    onDelete={handleDeleteProject}
                    onAddTask={handleAddTask} />

                  <List>
                  {projectTasks ?
                    projectTasks.map((task) => (
                      <Task task={task} key={task.id}
                        onChange={handleChangeTask}
                        onDelete={handleDeleteTask} />
                    ))
                    : <Stack alignItems="center">
                      {t('microprojects.empty_list.tasks')}
                    </Stack>
                  }
                  </List>
                </MKBox>
              );
            })
            : <Stack alignItems="center">
              {t('microprojects.empty_list.projects')}
            </Stack>
          }

          <Stack direction="column"
            width="stretch" padding={1} spacing={1}
            sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}>

            <Grid container>
              <Grid item xs={true}>
                <MKTypography variant='h6'>
                  {t('microprojects.settings.title')}
                </MKTypography>
              </Grid>
              <Grid item xs={1} minWidth={64}>
                <MKButton onClick={handleSettingsOpen} variant="text">
                  {settingsOpen ? <ExpandLess /> : <ExpandMore />}
                </MKButton>
              </Grid>
            </Grid>

            <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
              <Stack direction="column"
                width="stretch" padding={1} spacing={1}>
                <MKButton onClick={() => {
                  removeAllDone();
                }} color='warning' variant="contained">
                  {t('microprojects.remove_done.action')}
                </MKButton>

                <MKButton onClick={() => {
                  removeAllTasks();
                }} color='error' variant="contained">
                  {t('microprojects.remove_all_tasks.action')}
                </MKButton>

                <MKButton onClick={() => {
                  removeAllProjects();
                }} color='error' variant="contained">
                  {t('microprojects.remove_all_projects.action')}
                </MKButton>
              </Stack>
            </Collapse>
          </Stack>
        </Stack>
      </Grid>

      <Grid item md={12} lg={4}>
        <Stack direction="column"
          sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}
          width="stretch" padding={6} spacing={3}>
          <MKTypography variant='h6'>
            {t('microprojects.faq.title')}
          </MKTypography>

          <MKTypography variant='h6'>
            {t('microprojects.faq.why.question')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('microprojects.faq.why.answer')}
          </MKTypography>

          <MKTypography variant='h6'>
            {t('microprojects.faq.how.question')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('microprojects.faq.how.answer')}
          </MKTypography>

          <MKTypography variant='h6'>
            {t('microprojects.faq.data.question')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('microprojects.faq.data.answer')}
          </MKTypography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ProjectList;