import firebase from 'firebase/compat/app';

class FeedbacksServices {
  init = async function() {
    this.apiUrl = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/fb` : 'https://dev.weekendzapp.com/fb';
  }

  putFeedback = async function(text) {
    const partUrl = `/feedback`;
    const authHeaders = { mode: 'cors', headers: {
        'Authorization': 'Bearer ' + await firebase.auth().currentUser.getIdToken(),
        'Content-Type': 'application/json'
      }
    };
    return fetch(this.apiUrl + partUrl, { ...authHeaders,
      method: 'POST',
      body: JSON.stringify({ text: text })
    });
  }
};

export default FeedbacksServices;