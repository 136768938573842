import { useSnackbar } from 'notistack';
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, {Fragment, useEffect, useState} from "react";

const useNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(()=>{
        if(conf?.msg){
            let variant = 'info';
            if(conf.variant){
                variant = conf.variant;
            }
            enqueueSnackbar(conf.msg, {
                variant: variant,
                autoHideDuration: 5000,
                action: key => (
                    <Fragment>
                        <IconButton onClick={() => { closeSnackbar(key) }}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Fragment>
                )
            });
        }
    },[conf, enqueueSnackbar, closeSnackbar]);

    return [conf, setConf];
};

export default useNotification;