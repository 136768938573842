import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Grid, Stack } from '@mui/material';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import { Link as RouterLink } from 'react-router-dom';

const MemberAccountPage = () => {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    USER,
  } = controller;

  return (
    <Grid container>
      <Grid item xs={12} textAlign="center">
        <MKTypography variant="body2" m={1}>
          {t('account.current.title')}
        </MKTypography>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <MKTypography variant="h4">
            {USER.displayName}
          </MKTypography>

          <MKBox mx={1}
            display={{ lg: "flex" }}>
            <MKBox component="img" src={USER.photoURL}
              sx={{ border: 1, borderRadius: 4, borderColor: 'primary', width: '32px', height: '32px' }}>
            </MKBox>
          </MKBox>
        </Stack>
      </Grid>

      <Grid item xs={12} textAlign="center" m={2}>
        <MKButton color="secondary" component={RouterLink} to="/sign-out">
          {t('sign-out')}
        </MKButton>

        <MKTypography variant="body2" p={1}>
          {t('sign-out.details')}
        </MKTypography>
      </Grid>

      <Grid item xs={12} textAlign="center" m={2}>
        <MKButton color="error"
          href={"mailto:" + t('author.contact.email.address') + "?subject=" + t('remove-account.subject') + "&body=" + t('remove-account.body')}>
          {t('remove-account')}
        </MKButton>

        <MKTypography variant="body2" p={1}>
          {t('remove-account.details')}
        </MKTypography>
      </Grid>
    </Grid>
  );
}

export default MemberAccountPage;