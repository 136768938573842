import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Button, Collapse, Grid, Stack, TextField } from "@mui/material";
import { Checkbox, List, ListItem } from "@mui/material";
import { Add, Delete, Done, Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import tasksReducer from '../../reducers/TasksReducer.js';
import usePersistantState from '../../common/usePersistentState.js';
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';

const Task = ({ task, onChange, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <ListItem key={task.id}>
      <Grid container alignItems="center">
        <Grid item xs={1} minWidth={42}>
          <Checkbox checked={task.done}
            onChange={(event) => {
              onChange({
                ...task,
                done: event.target.checked
              });
            }}
          />
        </Grid>

        { isEditing ?
        <>
          <Grid item xs={true}>
          <TextField fullWidth
            value={task.text}
            onChange={(event) => {
              onChange({
                ...task,
                text: event.target.value
              });
            }}
            onKeyDown={(event) => {
              if (event.code === "Enter") {
                setIsEditing(false);
              }
            }} />
        </Grid>

        <Grid item xs={1} minWidth={42}>
          <MKButton onClick={() => setIsEditing(false)}
            width={42}>
            <Done />
          </MKButton>
        </Grid>
        </>
        :
        <>
        <Grid item xs={true}>
          <MKTypography variant='body2'>
            {task.text}
          </MKTypography>
        </Grid>
        <Grid item xs={1} minWidth={64}>
          <MKButton onClick={() => setIsEditing(true)}
                    variant="text">
            <Edit />
          </MKButton>
        </Grid>
      </>}
        <Grid item xs={1} minWidth={64}>
          <MKButton onClick={() => onDelete(task.id)}
            color='error' variant="text">
            <Delete />
          </MKButton>
        </Grid>
      </Grid>
    </ListItem>
  )
};

const TaskList = () => {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  const [tasks, dispatch] = useReducer(tasksReducer, [], handleLoad);
  const [newTaskText, setNewTaskText] = useState('');
  const [settingsOpen, setSettingsOpen] = usePersistantState(false);

  function handleAddTask(text) {
    dispatch({
      type: 'added',
      id: Math.random() * 10000,
      text: text,
    });
  }

  function handleChangeTask(task) {
    dispatch({
      type: 'changed',
      task: task
    });
  }

  function handleDeleteTask(taskId) {
    dispatch({
      type: 'deleted',
      id: taskId
    });
  }

  function removeAllDone() {
    dispatch({
      type: 'remove_done'
    });
  }

  function removeAll() {
    dispatch({
      type: 'clear'
    })
  }

  function handleStore() {
    localStorage.setItem("TASKS", JSON.stringify(tasks));
  }

  useEffect(() => {
    handleStore();
  });

  function handleLoad() {
    return JSON.parse(localStorage.getItem("TASKS")) ?? [];
  }

  function handleSettingsOpen() {
    setSettingsOpen(!settingsOpen);
  }

  return (
    <Grid container>
      <Grid item md={12} lg={8}>
        <Stack direction="column"
          padding={6} spacing={3}>

          <MKTypography variant='h4'>
            {t('tasklist.title')}
          </MKTypography>

          <MKTypography variant='h5'>
            {t('tasklist.subtitle')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('tasklist.description')}
          </MKTypography>

          <Grid container alignItems="center">
            <Grid item xs={true}>
              <TextField fullWidth
                value={newTaskText}
                onChange={(event) => {
                  setNewTaskText(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.code === "Enter") {
                    handleAddTask(newTaskText);
                    setNewTaskText('');
                  }
                }}
                label={t('tasklist.newtasktext.title')} />
            </Grid>

            <Grid item xs={1} minWidth={64}>
              <Button onClick={() => {
                handleAddTask(newTaskText);
                setNewTaskText('');
              }}>
                <Add />
              </Button>
            </Grid>
          </Grid>

          <List>
            {
              tasks && tasks.length > 0
                ? tasks.map((task) => (
                  <Task task={task} key={Math.random()}
                    onChange={handleChangeTask}
                    onDelete={handleDeleteTask} />
                ))
                : <Stack alignItems="center">
                  {t('tasklist.empty_list')}
                </Stack>
            }
          </List>

          <Stack direction="column"
            width="stretch" padding={1} spacing={1}
            sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}>

            <Grid container>
              <Grid item xs={true}>
                <MKTypography variant='h6'>
                  {t('tasklist.settings.title')}
                </MKTypography>
              </Grid>
              <Grid item xs={1} minWidth={64}>
                <MKButton onClick={handleSettingsOpen} variant="text">
                  {settingsOpen ? <ExpandLess /> : <ExpandMore />}
                </MKButton>
              </Grid>
            </Grid>

            <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
              <Stack direction="column"
                width="stretch" padding={1} spacing={1}>
                <MKButton onClick={() => {
                  removeAllDone();
                }} color='warning' variant="contained">
                  {t('tasklist.remove_done.action')}
                </MKButton>

                <MKButton onClick={() => {
                  removeAll();
                }} color='error' variant="contained">
                  {t('tasklist.remove_all.action')}
                </MKButton>
              </Stack>
            </Collapse>
          </Stack>
        </Stack>
      </Grid>

      <Grid item md={12} lg={4}>
        <Stack direction="column"
          sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}
          width="stretch" padding={6} spacing={3}>
          <MKTypography variant='h6'>
            {t('tasklist.faq.title')}
          </MKTypography>

          <MKTypography variant='h6'>
            {t('tasklist.faq.why.question')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('tasklist.faq.why.answer')}
          </MKTypography>

          <MKTypography variant='h6'>
            {t('tasklist.faq.how.question')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('tasklist.faq.how.answer')}
          </MKTypography>

          <MKTypography variant='h6'>
            {t('tasklist.faq.data.question')}
          </MKTypography>

          <MKTypography variant='body2'>
            {t('tasklist.faq.data.answer')}
          </MKTypography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default TaskList;