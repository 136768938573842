function mindMapReducer(mapRoot, action) {
  console.log(action);
  switch (action.type) {
    case 'added': {
      const existing = action.parent.children.find(c => c.id === action.item.id);
      if (!existing) {
        action.parent.children.push({
          ...action.item,
          children: [],
          parent: action.parent,
          level: (action.parent.level ?? 0) + 1,
          open: false
        }
        );
      }
      action.parent.open = true;
      return clone(mapRoot);
    }
    case 'changed': {
      if (action.item.parent) {
        const parent = action.item.parent;
        action.item.parent.children = action.item.parent.children.map(i => {
          if (i.id === action.item.id) {
            const newItem = { ...action.item, parent };
            newItem.children.forEach(c => c.parent = newItem);
            return newItem;
          }
          else return i;
        });
        return clone(mapRoot);
      }
      return mapRoot;
    }
    case 'deleted': {
      if (action.parent) {
        action.parent.children = action.parent.children.filter(i => i.id !== action.id);
        const cloned = clone(mapRoot);
        return cloned;
      }
      return mapRoot;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

function clone(obj) {
  return {
    ...obj,
    children: obj?.children?.map((c) => {
      return { ...c };
    })
  };
}

export default mindMapReducer;