import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import MKBox from 'components/MKBox';
import UptimeBarChart from 'examples/Charts/BarCharts/UptimeBarChart';

import { getLatest, getEarliest } from 'common/dateArrayHelpers';

const isSuccess = (status_code) => {
  return status_code >= 200 && status_code < 300;
}

const UptimeMonitor = ({ m, presenting }) => {
  const [data, setData] = useState([]);

  const uptimePerDay = () => {
    let days = [];
    for (let index = 29; index >= 0; index--) {
      const start = new Date();
      days.push(new Date(start.getFullYear(), start.getMonth(), start.getDate() - index))
    }

    const earliest = getEarliest(m.history);
    const earliestDate = new Date(earliest?.created_at).setHours(0, 0, 0, 0);
    const mappedHistory = m.history.map(m => {
      return { ...m, created_date: new Date(m.created_at) };
    });

    const greenUptime = "rgba(15, 235, 15, .8)";
    const yellowUptime = "rgba(15, 235, 235, .8)";
    const redUptime = "rgba(235, 15, 15, .8)";
    const grayUptime = "rgba(125, 125, 125, .8)";

    const uptime = days.map(d => {
      let value = 100;
      let backgroundColor = redUptime;
      let uptimePercentage = 0.0;
      if (earliest && earliestDate <= d) {
        const dayStart = new Date(d); dayStart.setHours(0, 0, 0, 0);
        const dayEnd = new Date(d); dayEnd.setHours(23, 59, 59, 999);
        // get latest status history record before the day
        const latestBefore = getLatest(mappedHistory.filter(h => h.created_date < dayStart));
        // get earliest status history record after the day
        //const earliestAfter = getEarliest(mappedHistory.filter(h => h.created_date > dayEnd));
        // get all status history records inside the day
        const midDayChanges = mappedHistory.filter(h => h.created_date > dayStart && h.created_date < dayEnd);

        if (latestBefore && midDayChanges.length === 0) {
          // there were no changes during this day compared to latestBefore
          uptimePercentage = isSuccess(latestBefore.new_response_status) ? 100 : uptimePercentage;
          backgroundColor = isSuccess(latestBefore.new_response_status) ? greenUptime : backgroundColor;
        }
        else if (!latestBefore && midDayChanges.length === 1) {
          // first day of the monitor with a single result that was not changing
          uptimePercentage = isSuccess(midDayChanges[0].new_response_status) ? 100 : uptimePercentage;
          backgroundColor = isSuccess(midDayChanges[0].new_response_status) ? greenUptime : backgroundColor;
        }
        else if (latestBefore && midDayChanges.length > 1) {
          let timeWhileUp = 0;

          let isUp = latestBefore.new_response_status === 200;
          timeWhileUp += isUp ? (midDayChanges[0].created_date.getTime() / 1000.0 - dayStart.getTime() / 1000.0) : 0;

          midDayChanges.forEach((c, i, a) => {
            const next = i + 1 < a.length ? a[i + 1] : undefined;
            const endOfSectionTime = next ? next.created_date : dayEnd;
            const isUp = isSuccess(c.new_response_status) || c.new_response_status === -1;
            timeWhileUp += isUp ? (endOfSectionTime.getTime() / 1000.0 - c.created_date.getTime() / 1000.0) : 0;
          });

          //const currentSeconds = new Date().getSeconds();
          //const secondsInDay = new Date() < dayEnd ? currentSeconds : 86400.0;
          uptimePercentage = (timeWhileUp / 86400.0) * 100;
          // console.log({d, times: {
          //   day_start_seconds: dayStart.getTime() / 1000,
          //   latest_before_seconds: latestBefore.created_date.getTime() / 1000,
          // }, timeWhileUp, uptimePercentage, latestBefore, midDayChanges});
        }
        // else {
        //   console.log({d, msg: "unprocessed"});
        // }

        if (uptimePercentage > 0.0) {
          value = uptimePercentage;
          backgroundColor = value > 99 ? greenUptime : yellowUptime;
        }

        //console.log({d, value, uptimePercentage, earliest, latestBefore, earliestAfter, midDayChanges, mappedHistory});
      }
      else {
        value = 100;
        backgroundColor = grayUptime;
      }

      return { day: d.toDateString(), value, backgroundColor, uptimePercentage }
    });
    //console.log(uptime);
    setData(uptime);
  }

  useEffect(() => {
    uptimePerDay();
    // eslint-disable-next-line
  }, [m])

  let url;
  let protocol = "https";
  if (m.url.indexOf(':') > 0) {
    const detectedProtocol = m.url?.split(':').filter(i => i!==undefined);
    protocol = detectedProtocol ?? "https";
    url = new URL(m.url);
  } else {
    url = new URL(protocol + "://" + m.url);
  }
  const DAYS_WHEN_NOT_PRESENTING = 14;

  return presenting ? (
    <MKBox p={1}>
      <UptimeBarChart
        //color={m.is_incident_ongoing ? "error" : m.had_incident_today ? "warning" : "info"}
        color={"dark"}
        heightOverride={"5rem"}
        title={url.hostname?.replace("www.", "")}
        last_checked={m.latest?.last_run_at}
        last_incident={m.last_incident?.created_at}
        chart={{
          labels: data.map(d => d.day),
          datasets: {
            label: "Percentage",
            data: data.map(d => d.value),
            backgroundColor: data.map(d => d.backgroundColor),
            uptimePercentages: data.map(d => d.uptimePercentage),
          },
        }}
        favicon={<Box component="img" width={40} height={40} mr={1}
          src={url.protocol + "//" + url.hostname + (url.port ? ":" + url.port : "") + "/favicon.ico"} />}
        url={m.url}
      />
    </MKBox>
  ) : (
    <MKBox p={1}>
      <UptimeBarChart
        //color={m.is_incident_ongoing ? "error" : m.had_incident_today ? "warning" : "info"}
        color={"dark"}
        heightOverride={"4.5rem"}
        title={url.hostname?.replace("www.", "")}
        //last_checked={m.latest?.last_run_at}
        //last_incident={m.last_incident?.created_at}
        chart={{
          labels: data.slice(30 - DAYS_WHEN_NOT_PRESENTING, 30).map(d => d.day),
          datasets: {
            label: "Percentage",
            data: data.slice(30 - DAYS_WHEN_NOT_PRESENTING, 30).map(d => d.value),
            backgroundColor: data.slice(30 - DAYS_WHEN_NOT_PRESENTING, 30).map(d => d.backgroundColor),
            uptimePercentages: data.slice(30 - DAYS_WHEN_NOT_PRESENTING, 30).map(d => d.uptimePercentage),
          },
        }}
        favicon={<Box component="img" width={40} height={40} mr={1}
          src={"http://" + url.hostname + (url.port ? ":" + url.port : "") + "/favicon.ico"} />}
        url={m.url}
        actionUrl={'/member/uptime/' + m.id}
      />
    </MKBox>
  );
}

export default UptimeMonitor;