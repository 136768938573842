/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { FamilyRestroom, Chat, Recommend, Games, Lightbulb } from "@mui/icons-material";

function Values() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h3" mb={6}>
              Personal values
            </MKTypography>
          </Grid>

          <Grid container justifyContent="flex-start" p={2}>
            <Grid item xs={12} md={6}>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon={<FamilyRestroom />}
                  title="Embrace"
                  description="Family and friends first; strangers and acquintances always treated with kindness, respect; lives and health over any other priority."
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon={<Games />}
                  title="Entertain"
                  description="Make others feel, think, laugh or amazed, to be able to disconnect from their problems for a short time"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox mb={{ xs: 5, md: 0 }}>
                <DefaultInfoCard
                  icon={<Lightbulb />}
                  title="Engage"
                  description="Teach, inspire thinking of new ideas of tech, principle or art"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox mb={{ xs: 5, md: 0 }}>
                <DefaultInfoCard
                  icon={<Recommend />}
                  title="Empower"
                  description="Enable others to follow and build upon, to achieve more in peace than was possible before"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox mb={{ xs: 5, md: 0 }}>
                <DefaultInfoCard
                  icon={<Chat />}
                  title="Enjoy"
                  description="Life is too short not to"
                />
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Values;
