exports.projectsReducer = (projects, action) => {
  switch (action.type) {
    case 'added': {
      return [...projects, {
        id: action.id,
        name: action.name,
      }];
    }
    case 'changed': {
      return projects.map(p => {
        if (p.id === action.project.id) {
          return { ...action.project };
        } else {
          return p;
        }
      });
    }
    case 'deleted': {
      return projects.filter(p => p.id !== action.id);
    }
    case 'clean': {
      return [];
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

exports.tasksReducer = (tasks, action) => {
  switch (action.type) {
    case 'added': {
      return [...tasks, {
        id: action.id,
        text: action.text,
        project_id: action.project_id,
        done: false
      }];
    }
    case 'changed': {
      return tasks.map(t => {
        if (t.id === action.task.id) {
          return { ...action.task };
        } else {
          return t;
        }
      });
    }
    case 'project_deleted': {
      return tasks.filter(t => t.project_id !== action.project_id);
    }
    case 'deleted': {
      return tasks.filter(t => t.id !== action.id);
    }
    case 'remove_done': {
      return tasks.filter(t => !t.done);
    }
    case 'clear': {
      return [];
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
