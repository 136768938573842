import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Button, Collapse, Grid, Link, List, ListItem, Stack, TextField, Switch, FormControlLabel } from "@mui/material";
import { Add, Delete, Done, Edit, ExpandLess, ExpandMore, Javascript } from '@mui/icons-material';
import mindMapReducer from '../../reducers/MindMapReducer';
import usePersistantState from '../../common/usePersistentState';
import MKTypography from 'components/MKTypography';

const MindMapItem = ({ item, onAdd, onChange, onDelete, presenting }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <ListItem key={item.id} alignItems='stretch'
      sx={{ display: 'list-item' }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={true}>
              {item.parent.parent.parent ?
                isEditing ?
                  <TextField value={item.name} fullWidth
                    label={t('mindmap.item.name.title')}
                    onChange={(event) => {
                      onChange({
                        ...item,
                        name: event.target.value
                      });
                    }}
                    onKeyDown={(event) => {
                      if (event.code === "Enter") {
                        setIsEditing(false);
                      }
                    }} />
                  : <MKTypography variant='body1'>
                    {item.name} {item.children.length > 0 ? <>({item.children.length})</> : <></>}
                  </MKTypography>
                : <MKTypography
                  variant='h6'>
                  {item.name} ({item.children.length})
                </MKTypography>
              }
            </Grid>

            {!presenting
              ? <>
                <Grid item xs={1} minWidth={64}>
                  <Button
                    onClick={() => {
                      setIsEditing(!isEditing)
                    }}>
                    {isEditing ? <Done /> : <Edit />}
                  </Button>
                </Grid>

                <Grid item xs={1} minWidth={64}>
                  <Button
                    onClick={() => {
                      onAdd(item);
                    }}>
                    <Add />
                  </Button>
                </Grid>
                <Grid item xs={2} minWidth={128}>
                  {item.parent.parent.parent ?
                    <Stack direction="row">
                      <Button onClick={() => {
                        onDelete(item);
                      }} color='error'>
                        <Delete />
                      </Button>

                      <Button onClick={() => {
                        onChange({
                          ...item,
                          open: !item.open
                        });
                      }}>
                        {item.open ? <ExpandLess /> : <ExpandMore />}
                      </Button>
                    </Stack>
                    : ''}
                </Grid>
              </>
              : item.children.length > 0
                ? <Grid item xs={1} minWidth={64}>
                  <Button onClick={() => {
                    onChange({
                      ...item,
                      open: !item.open
                    });
                  }}>
                    {item.open ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </Grid>
                : <></>
            }
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={item.open} timeout="auto" unmountOnExit>
            <List sx={{ listStyleType: item.level % 2 === 0 ? 'disc' : 'circle' }}>
              {item.children?.map((i) => <MindMapItem
                item={i}
                onAdd={onAdd}
                onChange={onChange}
                onDelete={onDelete}
                presenting={presenting}
              />)}
            </List>
          </Collapse>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const MindMap = () => {
  const [root, dispatch] = useReducer(mindMapReducer, undefined, handleLoad);
  const [presenting, setPresenting] = usePersistantState("MINDMAP_IS_PRESENTING", false);
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  function handleOnAdd(parent) {
    const item = {
      id: Math.random() * 1000000,
      name: "New Item"
    };

    dispatch({
      type: 'added',
      parent,
      item
    });
  }

  function handleOnChange(item) {
    dispatch({
      type: 'changed',
      item
    });
  }

  function handleOnDelete(item) {
    dispatch({
      type: 'deleted',
      parent: item.parent,
      id: item.id
    });
  }

  function handleStore() {
    localStorage.setItem("MINDMAP", jsonStringifyRecursive(root));
  }

  function jsonStringifyRecursive(obj) {
    const cache = new Set();
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.add(value);
      }
      return value;
    }, 4);
  }

  useEffect(() => {
    handleStore();
  });

  function handleLoad() {
    const data = localStorage.getItem("MINDMAP")
      ? JSON.parse(localStorage.getItem("MINDMAP"))
      : undefined;
    if (data) {
      restoreParents(data);
    }
    const r = {
      name: "ROOT",
      children: [],
      open: true
    };
    const rr = {
      name: "Second root",
      children: [],
      open: true,
      parent: r
    }
    r.children.push(rr);
    const rv = {
      name: "Visible ROOT",
      children: [],
      open: true,
      parent: rr
    };
    rr.children.push(rv);
    return data ?? r;
  }

  function restoreParents(obj) {
    if (obj.children) {
      obj.children.forEach(c => {
        c.parent = obj;
        c.level = (obj.level ?? 0) + 1;
        restoreParents(c);
      });
      return obj;
    }
    return undefined;
  }

  function downloadAsJSON() {
    const content = localStorage.getItem("MINDMAP");
    var a = document.createElement("a");
    var file = new Blob([content], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = "mindmap.json";
    a.click();
  }

  return (
    <Stack direction="column" alignItems="stretch">
      <Stack direction="column" alignItems="stretch"
        padding={6} maxWidth={1024} spacing={3}>

        <MKTypography variant='h4'>
          {t('mindmap.title')}
        </MKTypography>

        <MKTypography variant='h5'>
          {t('mindmap.subtitle')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('mindmap.description')}
        </MKTypography>

        <FormControlLabel control={<Switch
          checked={!presenting}
          onChange={(event) => {
            setPresenting(!event.target.checked);
          }} />}
          label={t('mindmap.switch.edit_mode')} />

        <List>
          <MindMapItem item={root.children[0].children[0]}
            onAdd={handleOnAdd}
            onChange={handleOnChange}
            onDelete={handleOnDelete}
            presenting={presenting} />
        </List>

        <Stack direction="column" alignItems="stretch"
          width="stretch" padding={1} spacing={1}
          sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}>

          <MKTypography variant='h6'>
            {t('mindmap.settings.title')}
          </MKTypography>

          {/* <Button endIcon={<Photo />} variant='contained'>
                        {t('mindmap.settings.save_as.png')}
                    </Button> */}

          <Button endIcon={<Javascript />} variant='contained'
            onClick={downloadAsJSON}>
            {t('mindmap.settings.save_as.json')}
          </Button>
        </Stack>
      </Stack>

      <Stack direction="column" alignItems="stretch"
        sx={{ background: ({ palette: { background, grey }, functions: { rgba } }) => darkMode ? background.default : rgba(grey[300], 0.8) }}
        width="stretch" padding={6} spacing={3}>
        <MKTypography variant='h6'>
          {t('mindmap.faq.title')}
        </MKTypography>

        <MKTypography variant='h6'>
          {t('mindmap.faq.why.question')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('mindmap.faq.why.answer')}
        </MKTypography>

        <MKTypography variant='h6'>
          {t('mindmap.faq.how.question')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('mindmap.faq.how.answer')}
        </MKTypography>

        <MKTypography variant='h6'>
          {t('mindmap.faq.data.question')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('mindmap.faq.data.answer')}
        </MKTypography>

        <MKTypography variant='h6'>
          {t('mindmap.faq.learnmore')}
        </MKTypography>
        <Link href='https://en.wikipedia.org/wiki/Mind_map'>
          Wikipedia - Mind map
        </Link>
      </Stack>
    </Stack>
  )
}

export default MindMap;