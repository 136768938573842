import en from "./en.png";
import hu from "./hu.png";
import ro from "./ro.png";

const flags = {
  en,
  hu,
  ro
};

const getFlag = (lang) => {
  return flags[lang] ?? en;
}

export {flags, getFlag};