import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from "@mui/material";
import MKBox from "components/MKBox";
import Background from "assets/images/bg-tenerife.jpeg";

const ComingSoon = () => {
  const { t } = useTranslation();

  return (
    <MKBox bgColor="white">
      <MKBox
        minHeight="15rem"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.6)
            )}, url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />

      <Stack direction="column" alignItems="center"
        padding={6} spacing={3}>
        <Typography variant='h4'>
          {t('coming-soon.title')}
        </Typography>

        <Typography variant='h5'>
          {t('coming-soon.subtitle')}
        </Typography>

        <Typography variant='body2'>
          {t('coming-soon.description')}
        </Typography>
      </Stack>
    </MKBox>
  )
}

export default ComingSoon;