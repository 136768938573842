/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// @mui material components
import { Button, Card, CardActionArea, Stack } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/UptimeBarChart/configs";
import { Error, OpenInNew, Schedule } from "@mui/icons-material";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function UptimeBarChart({ color, title, chart, last_checked, last_incident, favicon, url, actionUrl, heightOverride }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {}, (items) => {
    const first = items.find(i => i !== undefined);
    if (!first) return;
    return 'Uptime: ' + first?.dataset.uptimePercentages[first?.datasetIndex] + '%'
  });

  const wrapInActionArea = (content) => {
    return actionUrl ? 
      <CardActionArea LinkComponent={RouterLink} to={actionUrl}>
        {content}
      </CardActionArea>
    : content;
  }

  return (
    <Card sx={{ height: "100%" }}>
      { wrapInActionArea(
      <MKBox padding="1rem">
        {useMemo(
          () => (
            <MKBox
              variant="gradient"
              bgColor={color}
              borderRadius="md"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={0}
              height={heightOverride ?? "15.5rem"}
              width="100%"
            >
              <Bar data={data} options={options} redraw/>
            </MKBox>
          ),
          // eslint-disable-next-line
          [color, chart]
        )}
        <MKBox pt={2} pb={1} px={1}>
          <Stack direction="row">
            {favicon ? favicon : <></>}
            <MKTypography variant="h6">
              { title?.substring(0, Math.min(20, title.length)) }
              { actionUrl ? <></> : <Button href={url} target="blank" startIcon={<OpenInNew />} />}
            </MKTypography>
            {last_incident ?
              <MKBox display="flex" alignItems="center">
                <MKTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
                  <Error color="error" />
                </MKTypography>
                <MKTypography variant="button" color="text" fontWeight="light">
                  {new Date(last_incident).toLocaleString()}
                </MKTypography>
              </MKBox>
              : last_checked ?
                <MKBox display="flex" alignItems="center">
                  <MKTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
                    <Schedule />
                  </MKTypography>
                  <MKTypography variant="button" color="text" fontWeight="light">
                    {new Date(last_checked).toLocaleString()}
                  </MKTypography>
                </MKBox>
                : <></>}
          </Stack>
        </MKBox>
      </MKBox>
      )}
    </Card>
  );
}

// Setting default values for the props of UptimeBarChart
UptimeBarChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the UptimeBarChart
UptimeBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  last_checked: PropTypes.string,
  last_incident: PropTypes.string,
  favicon: PropTypes.any
};

export default UptimeBarChart;
