/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import ExperienceCard from "examples/Cards/BlogCards/ExperienceCard";

// Images
import bucharest from "assets/images/cities/bucharest.jpeg";
import cairo from "assets/images/cities/cairo.jpeg";
import london from "assets/images/cities/london.jpeg";
import zagreb from "assets/images/cities/zagreb.jpeg";
import baku from "assets/images/cities/baku.jpeg";
import almaty from "assets/images/cities/almaty.jpeg";
import berlin from "assets/images/cities/berlin.jpeg";
import paris from "assets/images/cities/paris.jpeg";

import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngular, faAws, faGolang, faJava, faMicrosoft, faNodeJs } from '@fortawesome/free-brands-svg-icons';

import { styled } from '@mui/material/styles';
import { faMobile } from "@fortawesome/free-solid-svg-icons";
const BoldChip = styled(Chip)({
  background: "lightgray",
  minHeight: "3em" 
});

function Experiences() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6} alignSelf="right">
          <MKTypography variant="h3">
            {t('author.experience.title')}
          </MKTypography>
          <MKTypography variant="body2" mb={6}>
            {t('author.experience.description')}
          </MKTypography>
        </Grid>

        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={zagreb}
              title={t('author.experience.projects.zagreb.city')}
              description={t('author.experience.projects.zagreb.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faNodeJs} size="xl" />}
                label="NodeJS" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={bucharest}
              title={t('author.experience.projects.bucharest.city')}
              description={t('author.experience.projects.bucharest.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faJava} size="xl"/>}
                label="SpringBoot" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={london}
              title={t('author.experience.projects.london.city')}
              description={t('author.experience.projects.london.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faAws} size="xl"/>}
                label="AWS" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={cairo}
              title={t('author.experience.projects.cairo.city')}
              description={t('author.experience.projects.cairo.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faGolang} size="xl"/>}
                label="GoLang" />}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={baku}
              title={t('author.experience.projects.baku.city')}
              description={t('author.experience.projects.baku.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faMobile} />}
                label="Native Mobile" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={almaty}
              title={t('author.experience.projects.almaty.city')}
              description={t('author.experience.projects.almaty.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faAngular} />}
                label="Angular" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={berlin}
              title={t('author.experience.projects.berlin.city')}
              description={t('author.experience.projects.berlin.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faMicrosoft} />}
                label="C#/C++" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ExperienceCard
              image={paris}
              title={t('author.experience.projects.emea.city')}
              description={t('author.experience.projects.emea.desc')}
              languageChip={<BoldChip
                icon={<FontAwesomeIcon icon={faNodeJs} />}
                label="NodeJS" />}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Experiences;
