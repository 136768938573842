import { useEffect } from 'react';
import { useMaterialUIController, signOut } from "context";
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    USER
  } = controller;
  const navigate = useNavigate();

  useEffect(() => {
    if (USER) {
      signOut(dispatch);
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return null;
}

export default SignOut;