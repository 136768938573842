import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import QRCode from "react-qr-code";
import { Link, Stack, TextField } from "@mui/material";
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';

const QRCodify = () => {
    const [content, setContent] = useState("");
    const { t } = useTranslation();
    const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

    return (
        <Stack direction={{ xs: "column", lg: "row" }} alignItems="stretch">
            <Stack direction="column" alignItems="stretch"
                padding={6} spacing={3}>

                <MKTypography variant='h4'>
                    {t('qrcodify.title')}
                </MKTypography>

                <MKTypography variant='h5'>
                    {t('qrcodify.subtitle')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('qrcodify.description')}
                </MKTypography>

                <TextField
                    id="contentText" name='contentText'
                    label={t('qrcodify.content.title')}
                    value={content}
                    multiline rows={6}
                    onChange={(event) => {
                        parseInt(setContent(event.target.value));
                    }
                    }
                />

                <Stack direction="column" spacing={2}>
                    <MKTypography variant='h6'>
                        {t('qrcodify.code.title')}
                    </MKTypography>

                    <MKBox sx={{ background: 'white' }} p={2}>
                      <QRCode value={content} />
                    </MKBox>
                </Stack>
            </Stack>

            <Stack direction="column" alignItems="stretch" sx={{ background: darkMode ? "dark" : "lightgray" }}
                width="stretch" padding={6} spacing={3}>
                <MKTypography variant='h6'>
                    {t('qrcodify.faq.title')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('qrcodify.faq.why.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('qrcodify.faq.why.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('qrcodify.faq.how.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('qrcodify.faq.how.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('qrcodify.faq.examples.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('qrcodify.faq.examples.answer')}
                </MKTypography>

                <MKTypography variant='body2'
                    paddingLeft={3}>
                    https://www.google.com
                </MKTypography>

                <MKTypography variant='body2'
                    paddingLeft={3}>
                    tel:0123123123
                </MKTypography>

                <MKTypography variant='body2'
                    paddingLeft={3}>
                    mailto:test@gmail.com
                </MKTypography>

                <MKTypography variant='body2'
                    paddingLeft={3}>
                    WIFI:S:MySSID;T:WPA;P:MyPassW0rd;;
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('qrcodify.faq.data.question')}
                </MKTypography>

                <MKTypography variant='body2'>
                    {t('qrcodify.faq.data.answer')}
                </MKTypography>

                <MKTypography variant='h6'>
                    {t('qrcodify.faq.learnmore')}
                </MKTypography>
                <Link href='https://en.wikipedia.org/wiki/QR_code'>
                    Wikipedia - QR code
                </Link>
            </Stack>
        </Stack>
    )
}

export default QRCodify;