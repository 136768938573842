import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';

import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

import FeedbacksServices from '../../services/feedbacks.service';
import useNotification from '../../common/useNotification';

const FeedbackPage = () => {
  const [ feedbackText, setFeedbackText ] = useState();
  const [ saving, setSaving ] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, sendNotification ] = useNotification();

  async function putFeedback(text) {
    if (saving) return;
    setSaving(true);
    const service = new FeedbacksServices();
    await service.init();
    await service.putFeedback(text);
    sendNotification({ msg: t('feedback.saved'), variant: 'success' });
    navigate("/");
  }

  return (
      <MKBox 
          sx={{
            '& .MuiTextField-root': { m: 1 },
            margin: 2
          }}
          p={2}
          component="form" noValidate>
        <MKTypography variant='h5'>
          {t('feedback.title')}
        </MKTypography>
        <MKTypography variant='body2' sx={{ marginBottom: 1 }}>
          {t('feedback.subtitle')}
        </MKTypography>

        <TextField
          required multiline rows={6}
          id="feedbackText"
          label={t('feedback.input')}
          value={feedbackText}
          onChange={evt => setFeedbackText(evt.target.value)}
          sx={{ width: '95%' }}
        />
        <MKTypography variant='body2' sx={{ marginLeft: 3 }}>
          {t('feedback.hint')}
        </MKTypography>

        <LoadingButton variant="contained" size="large"
                loading={saving}
                sx={{ width: '90%', margin: 2 }}
                onClick={() => putFeedback(feedbackText)}>
            {t('feedback.action')}
        </LoadingButton>
      </MKBox>
  )
}

export default FeedbackPage;