
import { Trans } from 'react-i18next';
import MKTypography from 'components/MKTypography';

const ft = (t, key) => {
  return (
    <MKTypography variant='body2'>
      <Trans>
        {t(key)}
      </Trans>
    </MKTypography>
  )
}

export { ft };