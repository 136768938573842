/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo } from "react";
import firebase from 'firebase/compat/app';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

function getToken() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      // console.log(idToken);
      window.__Token = idToken;
      return { token: idToken };
    }).catch(function (error) {
      console.log(error);
    });
  }
}

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "PRESENTING": {
      return { ...state, presenting: action.value };
    }
    case "DARKMODE": {
      localStorage.setItem("DARKMODE", action.value);
      return { ...state, darkMode: action.value };
    }
    case "SIGNOUT": {
      firebase.auth().signOut();
      return { ...state, USER: null, token: null };
    }
    case "SIGNIN": {
      return { ...state, USER: action.USER, token: getToken() };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    presenting: false,
    darkMode: localStorage.getItem("DARKMODE") === "true",
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setPresenting = (dispatch, value) => dispatch({ type: "PRESENTING", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const signIn = (dispatch, USER) => dispatch({ type: "SIGNIN", USER });
const signOut = (dispatch) => dispatch({ type: "SIGNOUT" });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setPresenting,
  setDarkMode,
  signIn,
  signOut
};
