import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes';

import UptimeEditMonitor from './uptime/UptimeEditMonitor';
import UptimeDashboard from './uptime/UptimeDashboard';
import ComingSoon from './surveys/ComingSoon';

function MainContainer(props) {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const getMemberRoutes = (allRoutes) => 
    allRoutes.map((route) => {
      if (route.collapse) {
        return getMemberRoutes(route.collapse);
      }

      if (route.route && route.route.startsWith("/member")) {
        return <Route exact path={route.route.replace("/member", "")} element={route.component} key={"member" + route.key} />;
      }

      return null;
    });

   function render() {
        return (
            <Routes>
                {getRoutes(routes(props))}
                {getMemberRoutes(routes(props))}
 
                <Route exact path="/uptime" element={<UptimeDashboard {...props} />} />
                <Route path="/uptime/:id" element={<UptimeEditMonitor {...props} />} />
                <Route exact path="/uptime/add" element={<UptimeEditMonitor {...props} />} />

                <Route path='/coming-soon' element={<ComingSoon {...props} />} />
            </Routes>
        )
    };

    return render();
}

export default MainContainer;