import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import MainContainer from './pages/MainContainer';

import MKBox from "components/MKBox";
import footerRoutes from "footer.routes";
import DefaultFooter from 'examples/Footers/DefaultFooter';
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

function ProtectedContent(props) {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  function render() {
    return (
      <MKBox>
        <DefaultNavbar
          routes={routes(props)}
          relative
        />
        
        <MKBox px={1} mt={2}>
          <MainContainer {...props} />
        </MKBox>

        <MKBox p={6} px={1} mt={12}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    );
  };
  return render();
}

export default withTranslation()(ProtectedContent);