import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css';
import { auth } from 'firebaseui';

var firebaseui = require('firebaseui');

const FirebaseUI = () => {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized) return;

    const authUi = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

    var uiConfig = {
      'callbacks': {
        'signInSuccessWithAuthResult': function(authResult, redirectUrl) {
          console.log(authResult);
          console.log(redirectUrl);
          if (authResult.user) {
            navigate("/");
          }
          // no redirect
          return false;
        },
          uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('loader').style.display = 'none';
          }
      },
      'signInFlow': 'popup',
      credentialHelper: auth.CredentialHelper.NONE,
      'signInOptions':[
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['https://www.googleapis.com/auth/plus.login'],
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account'
        }
      },
    ],
    // Terms of service url.
    'tosUrl': 'https://www.google.com'
    };

    authUi.reset();
    authUi.start('#firebaseui-container', uiConfig);
    setInitialized(true);
    // eslint-disable-next-line
  }, []);

    return (
      <div className="fb-ui-wrapper">
        <div id="firebaseui-container"></div>
        <div id="loader">Loading...</div>
      </div>
    );
}

export default FirebaseUI;