import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Switch, FormControlLabel, Badge } from "@mui/material";

import UptimeServices from 'services/uptime.service';
import useNotification from 'common/useNotification';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import { Add } from '@mui/icons-material';
import usePersistantState from 'common/usePersistentState';
import UptimeFAQ from './UptimeFAQ';
import { Link } from 'react-router-dom';
import { getLatest } from 'common/dateArrayHelpers';
import UptimeMonitor from './UptimeMonitor';

const UptimeDashboard = () => {
  const [monitors, setMonitors] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [, sendNotification] = useNotification();
  const service = new UptimeServices();
  const [presentation, setPresentation] = usePersistantState("UPTIME_STATS_PRESENTING", false);

  useEffect(() => {
    let refreshTimeout;

    const refreshCall = async () => {
      await refresh();
      refreshTimeout = setTimeout(refreshCall, 15000);
    }
    refreshCall();

    return () => {
      clearTimeout(refreshTimeout);
    }
    // eslint-disable-next-line
  }, []);

  const refresh = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await service.init();
      const today = new Date();
      const response = await service.listMonitors();
      const rms = response.monitors.map(m => {
        const schedules = response.schedules.filter(s => s.monitor_id === m.id);
        const history = response.status.filter(s => s.monitor_id === m.id);
        return {
          ...m,
          schedules,
          latest: getLatest(schedules),
          history, // excluding own errors from uptime calcs
          last_incident: getLatest(history.filter(h => h.new_response_status !== 200 && h.new_response_status !== -1)),
          is_incident_ongoing: getLatest(schedules)?.last_response_status !== 200,
          had_incident_today: getLatest(history.filter(h => h.new_response_status !== 200 && h.new_response_status !== -1 && new Date(h.created_at).toDateString() === today.toDateString()))
        }
      })
      setMonitors(rms); // TODO: optimize by introducing a reducer?
    }
    catch (err) {
      console.log(err);
      sendNotification({ msg: t('uptime.dashboard.refresh.failed'), variant: 'error' });
    }
    finally {
      setLoading(false);
    }
  };

  const presentationView = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel control={<Switch
            checked={presentation}
            onChange={(event) => {
              setPresentation(event.target.checked);
            }} />}
            label={t('uptime.switch.presentation_mode')} />
        </Grid>

        <Grid container justifyItems="stretch" alignItems="stretch">
          {
            monitors?.map(m => (
              <Grid item xs={12} md={6} lg={6} xl={4} key={m.id}>
                <UptimeMonitor m={m} presenting={presentation} />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    );
  }

  return presentation ? presentationView() : (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
        <Stack direction="column" alignItems="stretch"
          padding={1} spacing={3}>

          <Grid container spacing={1}>
            <Grid item xs={6} md={8}>
              <Stack direction="row">
                <MKTypography variant='h4' p={1}>
                  {t('uptime.title')}
                </MKTypography>
                <Badge badgeContent={t('badge.alpha')} color={"primary"} />
              </Stack>

              <MKTypography variant='h6' p={1}>
                {t('uptime.subtitle')}
              </MKTypography>

              <MKTypography variant='body2' p={1}>
                {t('uptime.description')}
              </MKTypography>
            </Grid>

            <Grid item xs={6} md={4}>
              <Stack direction="column" alignItems="stretch"
                padding={1} spacing={3}>
                <MKButton component={Link} to='/member/uptime/add'>
                  <MKTypography pr={2} variant='body2'>
                    <Add sx={{ transform: "scale(2.5)" }} />
                  </MKTypography>
                  <Stack direction="column">
                    <MKTypography variant="h5" component="div">
                      {t('uptime.add.title')}
                    </MKTypography>
                    <MKTypography variant="body2">
                      {t('uptime.add.description')}
                    </MKTypography>
                  </Stack>
                </MKButton>

                <FormControlLabel control={<Switch
                  checked={presentation}
                  onChange={(event) => {
                    setPresentation(event.target.checked);
                  }} />}
                  label={t('uptime.switch.presentation_mode')} />
              </Stack>
            </Grid>
          </Grid>

          <Grid container justifyItems="stretch" alignItems="stretch">
            {
              monitors?.map(m => (
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={m.id}>
                  <UptimeMonitor m={m} presenting={presentation} />
                </Grid>
              ))
            }
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
        <UptimeFAQ />
      </Grid>
    </Grid>
  );
}

export default UptimeDashboard;