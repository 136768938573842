import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePersistantState from 'common/usePersistentState';
import { useMaterialUIController } from "context";
import QRCode from "react-qr-code";
import { Box, Grid, Stack, TextField, Checkbox, FormControlLabel } from '@mui/material';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';

const PasswordGeneratorPage = () => {
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  const [password, setPassword] = useState("");
  const [length, setLength] = usePersistantState("PWD_LENGTH", 24);
  const [useUpperCase, setUseUpperCase] = usePersistantState("PWD_USE_UPPERCASE", true);
  const [useLowerCase, setUseLowerCase] = usePersistantState("PWD_USE_LOWERCASE", true);
  const [useNumbers, setUseNumbers] = usePersistantState("PWD_USE_NUMBERS", true);
  const [useSymbols, setUseSymbols] = usePersistantState("PWD_USE_SYMBOLS", false);
  const [avoidSimilarCharacters, setAvoidSimilarCharacters] = usePersistantState("PWD_AVOID_SIM_CHARS", true);
  const [blockLength, setBlockLength] = usePersistantState("PWD_BLOCK_LENGTH", 6);
  const { t } = useTranslation();

  function generate() {
    let password = "";

    const UPPERCASE_CHAR = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const EXC_UPPERCASE_CHAR = "ABCDEFGHJKLMNOPRSTUVWXYZ";
    const LOWERCASE_CHAR = "abcdefghijklmnopqrstuvwxyz";
    const EXC_LOWERCASE_CHAR = "abcdefghijkmnopqrstuvwxyz";
    const NUMBER_CHAR = "1234567890";
    const EXC_NUMBER_CHAR = "23456789";
    const SYMBOL_CHAR = "!\"#$%&'()*+,./:;<=>?@[\\]^_`{|}~";
    const EXC_SYMBOL_CHAR = "!\"#$%&'()*+,./:;<=>?@[\\]^_{|}~";

    let combined_characters = "";
    if (useUpperCase) {
      combined_characters += avoidSimilarCharacters ? EXC_UPPERCASE_CHAR : UPPERCASE_CHAR;
    }
    if (useLowerCase) {
      combined_characters += avoidSimilarCharacters ? EXC_LOWERCASE_CHAR : LOWERCASE_CHAR;
    }
    if (useNumbers) {
      combined_characters += avoidSimilarCharacters ? EXC_NUMBER_CHAR : NUMBER_CHAR;
    }
    if (useSymbols) {
      combined_characters += avoidSimilarCharacters ? EXC_SYMBOL_CHAR : SYMBOL_CHAR;
    }

    // TODO: generate crypto random
    // TODO: generate non-crypto random chars
    for (let i = 0; i < length; i++) {
      if (i > 0 && blockLength !== undefined && (i % blockLength === 0)) password += "-";
      password += combined_characters.charAt(Math.floor(Math.random() * combined_characters.length));
    }
    // TODO: generate expression from words
    // TODO: respect the selected internationalization language

    // TODO: generate arabic passwords
    // TODO: generate japanese passwords
    // TODO: generate chinese passwords
    // TODO: generate full unicode passwords
    setPassword(password);
  }

  useEffect(() => {
    if (password === "") generate();
  })

  return (
    <Grid
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
      container p={2}>
      <Grid item xs={true} />
      <Grid item xs={12} md={9} lg={6} p={1}>
        <Stack direction="column">
          <MKTypography variant='h3'>
            {t('passwordgen.title')}
          </MKTypography>
          <MKTypography variant='h6' sx={{ marginBottom: 1 }}>
            {t('passwordgen.subtitle')}
          </MKTypography>

          <MKTypography variant='h6' sx={{ marginLeft: 3 }}>
            {t('passwordgen.result')}
          </MKTypography>

          <MKBox sx={{ background: darkMode ? 'darkblue' : 'lightyellow' }}>
            <MKTypography variant='h4' sx={{ marginLeft: 3 }}>
              {password}
            </MKTypography>
          </MKBox>

          <MKTypography variant='body2' sx={{ marginLeft: 3 }}>
            {t('passwordgen.hint')}
          </MKTypography>

          <MKButton variant="contained"
            sx={{ margin: 1 }}
            onClick={() => generate()}>
            {t('passwordgen.action')}
          </MKButton>
        </Stack>
      </Grid>
      <Grid item xs={true} />

      <Grid container>
        <Grid item xs={true} />
        <Grid item xs={12} md={8} lg={6}>
          <Grid container alignItems="center" rowSpacing={1} marginTop={1}>
            <Grid item>
              <Box sx={{ background: 'white' }} p={2}>
                <QRCode value={password} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} p={1}>
              <MKTypography variant='h5' m={1}>
                {t('passwordgen.qr_code.title')}
              </MKTypography>

              <MKTypography variant='body2' m={1}>
                {t('passwordgen.qr_code.subtitle')}
              </MKTypography>

              <MKTypography variant='body2' m={1}>
                {t('passwordgen.qr_code.description')}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={true} />
      </Grid>

      <Grid container>
        <Grid item xs={true} />
        <Grid item sx={{ background: "#282c340A", padding: 2, marginTop: 1 }}
          xs={12} md={8} lg={6} p={2}>
          <MKTypography variant='h5'>
            {t('passwordgen.other_settings.title')}
          </MKTypography>

          <Stack direction={{ xs: "column", sm: "row" }}
            alignItems="center">
            <TextField
              id="lengthText"
              label={t('passwordgen.length.title')}
              value={length}
              onChange={(event) => {
                parseInt(setLength(event.target.value));
              }}
            />

            <MKTypography variant='body2'>
              {t('passwordgen.length.hint')}
            </MKTypography>
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }}
            alignItems="center">
            <TextField
              id="blockLengthText"
              label={t('passwordgen.block_length.title')}
              value={blockLength}
              onChange={(event) => {
                parseInt(setBlockLength(event.target.value));
              }}
            />

            <MKTypography variant='body2'>
              {t('passwordgen.block_length.hint')}
            </MKTypography>
          </Stack>

          <FormControlLabel control={<Checkbox defaultChecked
            value={useUpperCase}
            onChange={(event) => {
              setUseUpperCase(event.target.checked);
            }} />}
            label={t('passwordgen.other_settings.use_upper_case.label')}
            sx={{ width: 256 }}
          />

          <FormControlLabel control={<Checkbox
            checked={useLowerCase}
            onChange={(event) => {
              setUseLowerCase(event.target.checked);
            }} />}
            label={t('passwordgen.other_settings.use_lower_case.label')}
            sx={{ width: 256 }}
          />

          <FormControlLabel control={<Checkbox
            checked={useNumbers}
            onChange={(event) => {
              setUseNumbers(event.target.checked);
            }} />}
            label={t('passwordgen.other_settings.use_numbers.label')}
            sx={{ width: 256 }}
          />

          <FormControlLabel control={<Checkbox
            checked={useSymbols}
            onChange={(event) => {
              setUseSymbols(event.target.checked);
            }} />}
            label={t('passwordgen.other_settings.use_symbols.label')}
            sx={{ width: 256 }}
          />

          <FormControlLabel control={<Checkbox
            checked={avoidSimilarCharacters}
            onChange={(event) => {
              setAvoidSimilarCharacters(event.target.checked);
            }} />}
            label={t('passwordgen.other_settings.avoid_similar_characters.label')}
            sx={{ width: 256 }}
          />
        </Grid>
        <Grid item xs={true} />
      </Grid>
    </Grid>
  )
}

export default PasswordGeneratorPage;